<mat-dialog-content>
  <h2 class="title4-highlighted">Please crop your image</h2>
  <!-- <image-cropper
  [maintainAspectRatio]="true"
  [onlyScaleDown]="true"
  format="png"
  [imageFile]="data.file"
  [cropperStaticWidth]="246"
  [cropperStaticHeight]="437.33"
  (imageCropped)="imageCropped($event)"
> -->
  <image-cropper [maintainAspectRatio]="true" [onlyScaleDown]="true" format="png" [imageFile]="data.file"
    [aspectRatio]="0.76/1" (imageCropped)="imageCropped($event)">
  </image-cropper>
</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" class="btn btn-secondary-outline ms-2 text-primary-rev">Cancel</button>
  <button mat-button [mat-dialog-close]="result()" cdkFocusInitial class="btn btn-grad-ff ms-2">Done</button>
</div>