import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginPageComponent } from './modules/login/pages/login-page/login-page.component';

import { ToastrModule } from 'ngx-toastr';
import { ResetPasswordComponent } from './modules/login/pages/reset-password/reset-password.component';
import { NotificationComponent } from './modules/notifcation/notification/notification.component';
import { NotificationViewComponent } from './modules/notifcation/notification-view/notification-view.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    ResetPasswordComponent,
    NotificationComponent,
    NotificationViewComponent,
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,

    ToastrModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
