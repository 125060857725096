declare let google: any;

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { AuthService } from 'src/app/core/guard/auth.service';
import { ApiHelper } from 'src/app/core/service/api.helper';
import {
  defaultRules,
  validateFields,
} from 'src/app/shared/components/field-validation/validation-service';
import { SortingService } from 'src/app/core/service/sorting.service';
import { ProfileOnboadingPageComponent } from 'src/app/modules/profile/profile-onboading-page/profile-onboading-page.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Location } from '@angular/common';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup;
  signUpForm: FormGroup;
  forgotForm: FormGroup;
  verifyForm: FormGroup;
  resetPasswordForm: FormGroup;
  loginAccount: boolean = true;
  signinAccount: boolean = false;
  forgotAccount: boolean = false;
  verifyOtp: boolean = false;
  confirmPassword: boolean = false;
  googleSignInScriptLoaded: boolean = false;
  isGoogleLoginValid: boolean = true;
  defaultRules = defaultRules;
  token: any;
  mobile: any = '';
  countryInfo: any;
  isSearchEnabled = false;
  dropdownOpen = false;
  selectedCountry = { name: '', flag: '', dial_code: '' };
  filteredCountries: any;
  googleClientId: any;
  showPassword: boolean = false;
  visibility: boolean = false;
  newUrl: any;

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private router: Router,
    private authService: AuthService,
    private cookieService: CookieService,
    private apiHelper: ApiHelper,
    private sorting: SortingService,
    private dialog: MatDialog,
    private location: Location
  ) {
    this.loginForm = this.formBuilder.group({
      phoneNumber: ['', [Validators.required]],
      password: ['', Validators.required],
    });
    this.forgotForm = this.formBuilder.group({
      countryCode: [this.selectedCountry.dial_code],
      phoneNumber: ['', [Validators.required]],
    });
    this.verifyForm = this.formBuilder.group({
      otp: ['', Validators.required],
      uniqueToken: [''],
    });
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      uniqueToken: [''],
      payloadUrl:['']
    });

    this.signUpForm = this.formBuilder.group({
      phoneNumber: ['', Validators.required],
      password: ['', Validators.required],
      email: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    const agent = window.navigator.userAgent;
    this.newUrl = this.location.path();
    if (this.checkIsInAppBrowser(agent)) {
      this.isGoogleLoginValid = false;
    }

    this.checkSession();
    this.verifyOtp = false;
    const val = {
      type: 'googleClientId',
    };
    this.apiHelper
      .post(
        {
          criteria: val,
        },
        ApiEndPoints.googleMetaData,
        false
      )
      .subscribe((res) => {
        this.googleClientId = res.data.data;
        this.loadGoogleSignInScript();
      });

    this.apiHelper
      .post({ type: 'countryInfo' }, ApiEndPoints.masterFind, false)
      .subscribe((response) => {
        if (!this.isEmpty(response.data)) {
          this.countryInfo = this.sorting.sortJsonArray(
            response.data[0].data,
            'name'
          );
          this.filteredCountries = [...this.countryInfo];
          this.selectedCountry =
            this.countryInfo.find((c: any) => c.code === 'IN') ||
            this.countryInfo[0];
        }
      });
  }
  passwordVisibility() {
    this.showPassword = !this.showPassword;
  }
  confirmPasswordVisibility() {
    this.visibility = !this.visibility;
  }

  isEmpty(data: any): boolean {
    if (!data || !Array.isArray(data)) {
      return true;
    }
    return data.length === 0;
  }

  checkIsInAppBrowser(agent: string): boolean {
    const patterns = [
      /FBAN|FBAV/i,
      /Instagram/i,
      /Twitter/i,
      /LinkedInApp/i,
      /Snapchat/i,
      /GSA/i,
      /Line/i,
      /WhatsApp/i,
      /Telegram/i,
      /Pinterest/i,
      /Reddit/i,
      /Outlook/i,
    ];

    return patterns.some((pattern) => pattern.test(agent));
  }

  getErrorMessage(
    formGroup: FormGroup,
    controlName: string,
    errorKey: string
  ): string | null {
    const control = formGroup.get(controlName);
    if (control?.hasError(errorKey)) {
      return control.errors?.[errorKey] || null;
    }
    return null;
  }

  accountChange(): void {
    this.signinAccount = !this.signinAccount;
    this.loginAccount = !this.loginAccount;
  }

  onLoginSubmit(): void {
    if (this.loginForm.invalid) {
      return;
    }

    const credentials = {
      phoneNumber: this.loginForm.value.phoneNumber,
      password: this.loginForm.value.password,
    };

    this.loginService(credentials, ApiEndPoints.signIn);
  }

  onSignUpSubmit(): void {
    Object.keys(this.signUpForm.controls).forEach((key) => {
      const control = this.signUpForm.get(key);
      control?.setErrors(null);
    });

    const validationErrors = validateFields(this.signUpForm.value);

    if (Object.keys(validationErrors).length > 0) {
      for (const field in validationErrors) {
        const control = this.signUpForm.get(field);
        if (control) {
          control.setErrors({ customError: validationErrors[field] });
        }
      }
      return;
    }

    if (this.signUpForm.invalid) {
      return;
    }

    const signUpCredentials = {
      phoneNumber: this.signUpForm.value.phoneNumber,
      password: this.signUpForm.value.password,
      email: this.signUpForm.value.email,
      confirmPassword: this.signUpForm.value.confirmPassword,
      firstName: this.signUpForm.value.firstName,
      lastName: this.signUpForm.value.lastName,
    };

    this.loginService(signUpCredentials, ApiEndPoints.signUp);
  }

  loginService(data: any, apiEndPoint: string): void {
    const url = this.getCompanyUrl();
    data.payloadUrl = url;

    this.apiHelper.post(data, apiEndPoint, false).subscribe(
      (response) => {
        const { session } = response.data;
        localStorage.setItem('session', JSON.stringify(session));
        localStorage.setItem(
          'currentUser',
          JSON.stringify({ loginVal: 'logged' })
        );
        if (this.checkBodyContent(response.data, 'isOnBoard', false)) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.width = '100%';
          dialogConfig.data = { userData: response.data };
          dialogConfig.panelClass = 'custom-dialog-container';
          this.dialog.open(ProfileOnboadingPageComponent, dialogConfig);
        }
        this.authService.running = true;
        if (this.authService.running) {
          this.router.navigate(['/view']);
        }
      },
      (error) => {
        this.toastrService.error(error.error.message);
      }
    );
  }

  loadGoogleSignInScript(): void {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = false;
    script.defer = false;
    script.onload = () => {
      this.googleSignInScriptLoaded = true;
      this.initializeGoogleSignIn();
    };
    document.body.appendChild(script);
  }

  initializeGoogleSignIn(): void {
    if (
      typeof google !== 'undefined' &&
      google.accounts &&
      google.accounts.id
    ) {
      google.accounts.id.initialize({
        client_id: this.googleClientId,
        callback: this.handleCredentialResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true,
        prompt: 'select_account',
      });

      google.accounts.id.renderButton(document.getElementById('buttonDiv')!, {
        theme: 'filled_blue',
        size: 'large',
        shape: 'rectangular',
        width: 50,
        logo_alignment: 'center',
      });
    }
  }

  handleCredentialResponse(resp: any): void {
    const data = {
      credential: resp.credential,
    };

    this.loginService(data, ApiEndPoints.emailSignUP);
  }

  setCookieValue(value: string): void {
    this.cookieService.set('myCookie', value);
  }

  private checkSession(): void {
    const sessionString = localStorage.getItem('session');
    if (!sessionString) {
      return;
    }

    this.apiHelper.post({}, ApiEndPoints.checkAuth).subscribe(
      () => {
        this.router.navigate(['/view']);
      },
      () => {
        this.toastrService.error('Your session has expired, please login');
      }
    );
  }
  forgot() {
    this.loginAccount = !this.loginAccount;
    this.forgotAccount = !this.forgotAccount;
    this.isGoogleLoginValid = !this.isGoogleLoginValid;
  }
  forgotPass() {
    if (this.forgotForm.invalid) {
      return;
    }
    this.mobile = this.forgotForm.value.phoneNumber;
    this.apiHelper
      .post(this.forgotForm.value, ApiEndPoints.sendOtp, false)
      .subscribe(
        (response) => {
          this.token = response.data;
          this.verifyOtp = true;
        },
        (error) => {
          this.toastrService.error(error.error.message);
          this.forgotAccount = !this.forgotAccount;
        }
      );
    this.forgotAccount = !this.forgotAccount;
  }
  otpVerify() {
    if (this.verifyForm.invalid) {
      return;
    }
    this.verifyForm.get('uniqueToken')?.setValue(this.token);
    this.apiHelper
      .post(this.verifyForm.value, ApiEndPoints.verifyOtp, false)
      .subscribe(
        (response) => {
          const data = response.data;
          if (data) {
            this.confirmPassword = data;
          }
        },
        (error) => {
          this.toastrService.error(error.error.message);
          this.verifyOtp = !this.verifyOtp;
        }
      );
    this.verifyOtp = !this.verifyOtp;
  }

  passwordVerify() {
    Object.keys(this.resetPasswordForm.controls).forEach((key) => {
      const control = this.resetPasswordForm.get(key);
      control?.setErrors(null);
    });

    const validationErrors = validateFields(this.resetPasswordForm.value);

    if (Object.keys(validationErrors).length > 0) {
      for (const field in validationErrors) {
        const control = this.resetPasswordForm.get(field);
        if (control) {
          control.setErrors({ customError: validationErrors[field] });
        }
      }
      return;
    }

    if (this.resetPasswordForm.invalid) {
      return;
    }
    this.resetPasswordForm.get('uniqueToken')?.setValue(this.token);
    const url = this.getCompanyUrl();
    this.resetPasswordForm.get('payloadUrl')?.setValue(url);
    this.apiHelper
      .post(this.resetPasswordForm.value, ApiEndPoints.newPassword, false)
      .subscribe(
        (response) => {
          const { session } = response.data;

          localStorage.setItem('session', JSON.stringify(session));
          localStorage.setItem(
            'currentUser',
            JSON.stringify({ loginVal: 'logged' })
          );
          if (this.checkBodyContent(response.data, 'isOnBoard', false)) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.width = '100%';
            dialogConfig.data = { userData: response.data };
            dialogConfig.panelClass = 'custom-dialog-container';
            this.dialog.open(ProfileOnboadingPageComponent, dialogConfig);
          }
          this.authService.running = true;
          if (this.authService.running) {
            this.router.navigate(['/view']);
          }
        },
        (error) => {
          this.toastrService.show(error.error.message);
          this.confirmPassword = !this.confirmPassword;
        }
      );
  }
  verify() {
    this.loginAccount = !this.loginAccount;
    this.verifyOtp = !this.verifyOtp;
    this.isGoogleLoginValid = !this.isGoogleLoginValid;
  }
  reset() {
    this.loginAccount = !this.loginAccount;
    this.confirmPassword = !this.confirmPassword;
    this.isGoogleLoginValid = !this.isGoogleLoginValid;
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
    this.filteredCountries = [...this.countryInfo]; // Reset the list when opening
  }

  updateSelectedCountry(country: any) {
    this.selectedCountry = country;
    this.forgotForm.patchValue({ countryCode: country.code });
    this.dropdownOpen = false;
  }

  onSearch(event: Event) {
    const searchText = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredCountries = this.countryInfo.filter((country: any) =>
      country.name.toLowerCase().includes(searchText)
    );
  }
  checkBodyContent(jsonBody: any, key: string, value: any): boolean {
    return jsonBody !== null && key in jsonBody && jsonBody[key] === value;
  }



  getCompanyUrl() {
    const domainName = location.origin;
    const urlObj = new URL(domainName);
    let hostname = urlObj.hostname;

    if (hostname.startsWith('fe.')) {
      hostname = hostname.substring(3); // Remove "fe."
    }

    return hostname;
  }
}
