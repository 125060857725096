<div *ngIf="type === 'searcha'">
  <div class="col-md-5 search-container" (mouseout)="focusOnList = false">
    <input
      class="input-field form-control search text-white mr-sm-2"
      type="text"
      [(ngModel)]="searchQuery"
      (input)="onSearch()"
      placeholder="Search"
      aria-label="Search"
    />

    <div
      class="search-results input-field text-capitalize"
      *ngIf="searchResults.length"
    >
      <ul>
        <li
          *ngFor="let result of searchResults"
          (click)="onProfileClick(result.id)"
        >
          {{ result.name }}
        </li>
      </ul>
    </div>
  </div>
</div>
<div *ngIf="type === 'search'">
  <div class="col-md-5 search-container" (mouseout)="focusOnList = false">
    <input
      class="input-field form-control search text-white mr-sm-2"
      type="text"
      [(ngModel)]="searchQuery"
      (input)="onSearch()"
      placeholder="Search"
      aria-label="Search"
    />
  </div>
</div>

<div *ngIf="type === 'add'" class="bg-primary-400">
  <div
    class="col-md-5 search-container bg-primary-400"
    (mouseout)="focusOnList = false"
  >
    <input
      class="input-field form-control search mr-sm-2"
      type="search"
      [(ngModel)]="searchQuery"
      (input)="onSearch()"
      placeholder="Search"
      aria-label="Search"
    />


    <div class="search-results" *ngIf="searchResults.length">
      <div *ngFor="let result of searchResults" class="p-2">
        <div
          class="d-flex justify-content-between align-items-center mb-2 mt-2"
        >
          <div class="d-flex">
            <h4 class="m-0 p-0">{{ result.name }}</h4>
            <h4 class="m-0 p-0">{{ result.industryRole }}</h4>
          </div>
          <div>
            <button class="btn btn-grad-ff text-white" (click)="onInvite(result)">
              add
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div  class="bg-primary-400">
  <div
    class="col-md-5 search-container bg-primary-400"
    (mouseout)="focusOnList = false"
  >
    <input
      class="input-field form-control search mr-sm-2"
      type="search"
      [(ngModel)]="searchQuery"
      (input)="onSearchList()"
      placeholder="Search"
      aria-label="Search"
    />


    <!-- <div class="search-results" *ngIf="searchResults.length">
      <div *ngFor="let result of searchResults" class="p-2">
        <div
          class="d-flex justify-content-between align-items-center mb-2 mt-2"
        >
          <div class="d-flex">
            <h4 class="m-0 p-0">{{ result.name }}</h4>
            <h4 class="m-0 p-0">{{ result.industryRole }}</h4>
          </div>
          <div>
            <button class="btn btn-grad-ff text-white" (click)="onInvite(result)">
              add
            </button>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>
