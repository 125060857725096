import { Component, OnInit } from '@angular/core';
import { sidebarList } from 'src/app/core/constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit{
  sidebarData = sidebarList;
  ngOnInit(): void {
    this.sidebarData = sidebarList;
  }
}
