// auth.service.ts

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private isLoggedInFlag = false;
  running: boolean = false;
  isAdmin: boolean = false;

  constructor() {
    this.running = !!localStorage.getItem('currentUser');
  }

  isLoggedIn(): boolean {   // Check your authentication status here (token, session, etc.)
    return this.running;
  }

  login(): void {
    // Perform login actions (set token, session, etc.)
    this.isLoggedInFlag = true;
    // You may save authentication information here
  }

  logout(): void {
    // Perform logout actions (clear token, session, etc.)
    this.isLoggedInFlag = false;
    // Clear saved authentication information here
  }

  isAdminCheck(): boolean {
    let userInfo = localStorage.getItem('profile');
    if (!userInfo) {
      return this.isAdmin = false
    }
    let userInfoParse = JSON.parse(userInfo)
    if (userInfoParse.roles.includes("admin")) {
     this.isAdmin = true
    }else{
      this.isAdmin = false
    }
    // Check your authentication status here (token, session, etc.)
    return this.isAdmin;
  }
}