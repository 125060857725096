<div class="container pad-t">
    <div class="text-white">
        <div class="d-flex justify-content-between align-items-center ">
            <h2><i class="fa fa-solid fa-chevron-left pe-2 cursor-hand" (click)="backToProfileList()"> Notification</i></h2>
            <span class="text-ff-secondary text-cfont-secondary2 cursor-hand">Mark all as Read</span>
        </div>
    </div>

    <div *ngFor="let item of notification">
        <div class="card-wrap card-wrap--hover rounded p-3 pe-4 ps-4 pt-4 pb-3 mb-4"
            (click)="goToProject(item.notificationDetails.projectId)">
            <div class="d-flex justify-content-between align-items-start">
                <div class="text-white">
                    {{ item.message }}
                </div>
                <span class="text-secondary">{{ item.createdAt | date:'short' }}</span>
            </div>

            <div class="d-flex justify-content-between align-items-center mt-3">
                <div></div>
                <div *ngIf="item.notificationAction === 'action' && item.notificationType === 'invitation'">
                    <button class="btn btn-primary me-2" (click)="invitationAccept(item.entrollmentId);$event.stopPropagation();">
                        Accept
                    </button>
                    <button class="btn btn-danger" (click)="invitationCancel(item.entrollmentId);$event.stopPropagation();">
                        Decline
                    </button>
                </div>

                <div class="ps-2"
                    *ngIf="item.notificationAction === 'action' && item.notificationType === 'investment'">
                    <button class="btn btn-grad-ff text-white">Invest</button>
                </div>
            </div>
        </div>
    </div>