// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class SpinnerService {

//   constructor() { }
// }
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  constructor(private spinner: NgxSpinnerService) {}

  // Show the spinner
  show() {
    this.spinner.show(undefined, {
      bdColor: 'rgba(0, 0, 0, 0.8)',
      size: 'medium',
      color: 'rgba(104, 80, 253, 1)',
      // color: "btn-grad-ff",
      type: 'ball-clip-rotate-pulse',
      fullScreen: true,
    });
  }

  // Hide the spinner
  hide() {
    this.spinner.hide();
  }
}

