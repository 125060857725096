<header>
  <nav class="navbar navbar-lg banner">
    <div class="container mobile">
      <div class="logo lap">
        <img
          src="assets/images/img/ieelogowhite.webp"
          alt=""
          [routerLink]="['/view']"
        />
      </div>
     
        <!-- <marquee>
          Project Management & Investor Relationship Coming Soon.
        </marquee> -->
      <div class="logo lap sm">
        <img
          src="assets/images/img/ieelogowhite.webp"
          alt=""
          [routerLink]="['/view']"
        />
      </div>
      <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> -->
      <!-- <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button> -->

      <div class="d-flex justify-content-between pt-1">
        <!-- <form class="form-inline my-2 my-lg-0">
          <app-search type="search"></app-search>
        </form> -->
        <div class="text-white me-2 point">
          <ul class="navbar-nav mx-auto">
            <div class="d-flex justify-content-between">
            <li class="nav-item active">
              <h3 class="nav-link text-cfont-secondary2 me-5 cursor-hand" [routerLink]="['/view']">Profile </h3>
            </li>
            <li class="nav-item">
              <h3 class="nav-link text-cfont-secondary2 me-5 cursor-hand" [routerLink]="['/view/projects']">Project</h3>
            </li>
            <li class="nav-item">
              <h3 class="nav-link text-cfont-secondary2 me-5 cursor-hand" [routerLink]="['/view/vendor']">Vendor</h3>
            </li>
          </div>
          </ul>
        </div>
        <div class="anim-i pt-2 pe-2">
          <h3>

            <i
            class="fa fa-regular fa-bell text-white"
            (click)="notificationPop()"
            >
          </i>
        </h3>
        </div>

        <div
          class="profile-pic-sm mx-2 d-flex align-items-center justify-content-center cursor-hand"
          (click)="viewProfile(userSession.id)"
        >
          <img
            class="img-fluid"
            [src]="userSession?.pictureFileLocations"
            alt="profile pic"
            onerror="this.onerror=null;this.src='assets/images/img/profile-avatar-1.png';"
          />
        </div>
      </div>
    </div>
  </nav>
</header>

<!-- 
  <div class="navbar">
    <div class="navdiv">
      <div class="content_left">
        
      <img
      src="../../../../assets/images/img/ieelogowhite.webp"
      alt=""
        [routerLink]="['/view']"
        />
        
        <h1>Crowd Funding</h1>
      <h1>FF Exclusive</h1>
      <h1>Exclusive</h1>
    </div>
    <div class="content_right">
      <div class="searchfiled">
        <div class="brand-ppinput brand-ppinput--search-right">
          <div class="brand-ppinput__search-icon">
            <em class="brand-icon brand-icon-Search"></em>
          </div>
          <input
            type="text"
            class="brand-ppinput--search-right mb-3"
            placeholder="Search..."
          />
        </div>
      </div>
      <div class="imgComp" (click)="viewProfile(userSession.id)">
        <img
          src="{{ userSession?.pictureFileLocations }}"
          alt="image"
          onerror="this.src ='../../../../assets/images/svg/profile 2.svg' "
        />
      </div>
    </div>
  </div>
</div> -->
