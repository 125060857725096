import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SortingService {
  
  sortArrayAlphabetically(arr: string[]): string[] {
    return [...arr].sort((a, b) => a.localeCompare(b));
  }
}
