<div class="container pad-t">
  <div class="row mb-3">
    <div class="col-md-12">
      <span class="text-cfont-secondary2 cursor-hand float-end">Mark all as Read</span>
    </div>
  </div>
  <!-- <div class="container title title6-highlighted" *ngIf="checkIfEmpty">
  <h1 class="text-center">NO NOTIFICATIONS YET</h1>
</div>
  <div class="action-btn ms-3">
                <a href="#"><i class="fa fa-edit"></i></a>
            </div>
  <div> -->
  <div *ngFor="let item of notificationArray">
    <div class="row mb-2">
    </div>
    <div class="card-wrap card-wrap--hover rounded p-4 mb-4" (click)="goToProject(item.id)">
      <div class="d-flex align-items-center">
        <div class="position-relative nofication-ff user-thumb">
          <img src="assets/images/user-img.png" alt="" />
          <span class="position-absolute top-0 start-100 translate-middle p-1 bg-success rounded-circle">
            <span class="visually-hidden">New alerts</span>
          </span>
        </div>
        <div class="float-start detail-dv flex-grow-1 ms-3">
          <h6 class="title6-highlighted">Invitation</h6>
          <h6 class="title6-dim mb-2">
            {{ item.content }}
          </h6>
          <p class="title-normal">{{ item.createdAt | date : "short" }}</p>
        </div>
        <div class="w-50p">
        </div>
      </div>
      <div class="float-end">
        <button *ngFor="let btn of getButtons(item.actionType)" [ngClass]="btn.style"
          (click)="handleButtonClick(btn.action, item.id)">
          {{btn.label}}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- <div *ngIf="!checkIfEmpty">
  <div *ngFor="let item of notification">
    <div class="row mb-2">
    </div>
    <div class="card-wrap card-wrap--hover rounded p-4 mb-4" (click)="goToProject(item.notificationDetails.projectId)">
      <div class="d-flex align-items-center">
        <div class="position-relative nofication-ff user-thumb">
          <img src="assets/images/user-img.png" alt="" />
          <span class="position-absolute top-0 start-100 translate-middle p-1 bg-success rounded-circle">
            <span class="visually-hidden">New alerts</span>
          </span>
        </div>
        <div class="float-start detail-dv flex-grow-1 ms-3">
          <h6 class="title6-highlighted">Invitation</h6>
          <h6 class="title6-dim mb-2">
            {{ item.message }}
          </h6>
          <p class="title-normal">{{ item.createdAt | date : "short" }}</p>
        </div>
        <div class="w-50p">
        </div>
      </div>
    </div> -->