import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss'],
})
export class ImageCropComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: { file: File }) {}
    croppedImage: any
    result(){
    return  this.croppedImage
    }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.blob
  }


}
