import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class dataformatorService {
  locationArray(location1: any, location2: any) {
    return location1 && location2
      ? `${location1}, ${location2}`
      : location1
      ? `${location1}`
      : location2
      ? `${location2}`
      : '';
  }

  multiple(roleLists: any) {
    if (roleLists == 0) {
      return '';
    } else if (roleLists.length <= 1) {
      return roleLists.join(', ');
    } else {
      return `${roleLists[0]}, ${roleLists.length - 1} other`;
    }
  }

  formatDate(isoString: Date) {
    if(isoString){
      const date = new Date(isoString);
      let options: {} = {
        year: 'numeric',
        month: 'short',
      };
      
      return date.toLocaleDateString('en-US', options);
    }
    return
  }

  dateFormat(fromDate: any, toDate: any) {
let date1 = this.formatDate(fromDate)
let date2 = this.formatDate(toDate)


    return date1 && date2
      ? `${date1} - ${date2}`
      : date1
      ? `${date1}`
      : date2
      ? `${date2}`
      : '';
  }
}
