<div class="smallHeader">
  <div class="container-img">
    <div class="container-lg">
      <div class="col-lg-5 col-md-6 col-sm-12">
        <div class="col point">
          <h1 class="title font-font-primary text-white mb-0">
            Spotlight Your <span class="story">Story!</span>
          </h1>
        </div>
        <div class="row point">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h4 class="text-white font-font-secondary2 mt-3">
              Create your profile with your personal journey, projects,
              achievements, and awards. Let the world see your work, connect
              with industry professionals, and unlock exclusive features that
              celebrate your unique cinematic path.
            </h4>
          </div>
        </div>

        <div class="d-flex flex-wrap gap-2 mb-3 mb-md-0">
          <button
            class="btn btn-grad-ff text-white mt-3"
            [routerLink]="['profile/view', userProfile]"
          >
            Create Your Profile
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="body-bg">
  <div class="mb-4 pt-5">
    <div class="pb-4">
      <div class="d-flex justify-content-center mobile flex-wrap">
        <div class="pe-2">
          <app-search class="w-100 pb-2"  (searchResultList)="profileSearchResultData($event)"></app-search>
        </div>
        <div class="pe-2">
          <select
            class="ps-2 input-field form-control search text-white text-capitalize"
             #locationsSelect
            (change)="searchFilter('location', $event)"
          >
            <option value="" style="display: none">location</option>
            <option *ngFor="let location of locations" [value]="location">
              {{ location }}
            </option>
          </select>
        </div>

        <div class="pe-2">
          <select
            class="ps-2 input-field form-control search text-white text-capitalize"
            #rolesSelect
            (change)="searchFilter('role', $event)"
          >
            <option value="" style="display: none">role</option>
            <option
              class="dropdown-value"
              *ngFor="let role of roleLists"
              [value]="role"
            >
              {{ role }}
            </option>
          </select>
        </div>
        <button class="btn btn-grad-ff text-white ms-2" (click)="onCancel(locationsSelect,rolesSelect)">
          Clear
        </button>
      </div>

      <!-- </div> -->
    </div>
  </div>
  <div class="container-lg">
    <div class="row">
      <div
        class="col-size text-white"
        *ngFor="let item of profiles"
        [routerLink]="['profile/view', item.id]"
      >
        <!-- <div *ngIf="isValid(item)"> -->
        <div class="card-wrap card-wrap--hover rounded pb-3">
          <div class="profile-cardPic">
            <img
              [src]="item.pictureFileLocations"
              onerror="this.onerror=null;this.src='assets/images/img/avatar.jpg';"
              alt="Card image cap"
            />
          </div>
          <div class="ms-4">
            <h3 class="mb-0 mt-4 mb-2 fw-bold text-capitalize">
              {{ item.name }}
            </h3>
            <h5>
              <img
                src="../../../../assets/images/svg/theaters.svg"
                class="me-2"
              />
              {{ dataFormator.multiple(item?.industryRole) }}
            </h5>
            <h5 class="text-capitalize">
              <img
                src="../../../../assets/images/svg/location_on.svg"
                class="me-2 mb-1"
              />{{
                dataFormator.locationArray(
                  item?.location?.city,
                  item?.location?.country
                )
              }}
            </h5>
          </div>
          <!-- </div> -->
          <!-- <a
          class="btn btn-grad-ff text-white mt-2"
          [routerLink]="['profile/view', item.id]"
          >View More</a
          > -->
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="pagination">
    <button
      class="pagination-button"
      *ngIf="count != 1"
      (click)="pagenation('previous')"
    >
      &laquo; Prev
    </button>
    <button
      class="pagination-button"
      *ngIf="profileData"
      (click)="pagenation('next')"
    >
      Next &raquo;
    </button>
  </div>
</div>
