import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-notification-view',
  templateUrl: './notification-view.component.html',
  styleUrls: ['./notification-view.component.scss'],
})
export class NotificationViewComponent {
  notification: any = [];
  data: any = [];
  checkIfEmpty: boolean = true;
  notificationArray = [
    {
      id: '2318071007686',
      userId: '2000012321046',
      templateId: '2226221978964',
      enrollmentId: '122',
      content:
        "🎬 Lights, camera, action! We're thrilled to have your creative vision join our community of storytellers.",
      status: 'SENT',
      channel: 'IN_APP',
      actions: {
        userId: '2000012321046',
        userName: 'sedfws dvf',
      },
      type: 'action',
      actionType: 'invitation',
      readAt: null,
      clickedAt: null,
      expiresAt: null,
      createdAt: '2025-01-15T13:55:45.983Z',
      updatedAt: '2025-01-24T12:40:47.343Z',
    },
    {
      id: '3456789012345',
      userId: '2000012398765',
      templateId: '2226221987654',
      enrollmentId: '321',
      content:
        '🚀 Your application for the innovation program has been received!',
      status: 'PENDING',
      channel: 'EMAIL',
      actions: {
        userId: '2000012398765',
        userName: 'John Doe',
      },
      type: 'informational',
      actionType: 'update',
      readAt: '2025-01-20T10:30:12.123Z',
      clickedAt: '2025-01-20T10:32:00.456Z',
      expiresAt: null,
      createdAt: '2025-01-19T08:22:33.654Z',
      updatedAt: '2025-01-20T10:32:00.789Z',
    },
    {
      id: '5678901234567',
      userId: '2000012456789',
      templateId: '2226221972345',
      enrollmentId: '654',
      content: "🎉 Congrats! You've been awarded the top contributor badge.",
      status: 'SENT',
      channel: 'IN_APP',
      actions: {
        userId: '2000012456789',
        userName: 'Jane Smith',
      },
      type: 'success',
      actionType: 'reward',
      readAt: null,
      clickedAt: null,
      expiresAt: '2025-02-01T23:59:59.999Z',
      createdAt: '2025-01-21T12:10:45.543Z',
      updatedAt: '2025-01-21T12:10:45.876Z',
    },
    {
      id: '6789012345678',
      userId: '2000012567890',
      templateId: '2226221998765',
      enrollmentId: '987',
      content: '⚠️ Your session will expire soon. Please save your work.',
      status: 'SENT',
      channel: 'IN_APP',
      actions: {
        userId: '2000012567890',
        userName: 'Alice Johnson',
      },
      type: 'warning',
      actionType: 'session_timeout',
      readAt: null,
      clickedAt: null,
      expiresAt: '2025-01-25T14:00:00.000Z',
      createdAt: '2025-01-23T09:45:22.789Z',
      updatedAt: '2025-01-23T09:45:22.987Z',
    },
    {
      id: '7890123456789',
      userId: '2000012678901',
      templateId: '2226221934567',
      enrollmentId: '159',
      content: '❌ Payment failed. Please try again.',
      status: 'FAILED',
      channel: 'EMAIL',
      actions: {
        userId: '2000012678901',
        userName: 'Michael Brown',
      },
      type: 'error',
      actionType: 'payment_failure',
      readAt: null,
      clickedAt: '2025-01-24T11:05:30.654Z',
      expiresAt: null,
      createdAt: '2025-01-24T10:50:11.876Z',
      updatedAt: '2025-01-24T11:05:30.876Z',
    },
    {
      id: '8901234567890',
      userId: '2000012789012',
      templateId: '2226221945678',
      enrollmentId: '753',
      content:
        '📅 Reminder: Your appointment is scheduled for tomorrow at 3 PM.',
      status: 'SENT',
      channel: 'SMS',
      actions: {
        userId: '2000012789012',
        userName: 'Emma Wilson',
      },
      type: 'reminder',
      actionType: 'appointment',
      readAt: null,
      clickedAt: null,
      expiresAt: '2025-01-25T15:00:00.000Z',
      createdAt: '2025-01-24T14:30:45.123Z',
      updatedAt: '2025-01-24T14:30:45.321Z',
    },
    {
      id: '9012345678901',
      userId: '2000012890123',
      templateId: '2226221956789',
      enrollmentId: '852',
      content: '🎁 Special offer: Get 30% off your next purchase!',
      status: 'SENT',
      channel: 'PUSH',
      actions: {
        userId: '2000012890123',
        userName: 'Oliver Harris',
      },
      type: 'promotion',
      actionType: 'discount',
      readAt: '2025-01-24T16:45:00.987Z',
      clickedAt: '2025-01-24T16:50:12.345Z',
      expiresAt: '2025-01-30T23:59:59.999Z',
      createdAt: '2025-01-24T16:40:10.654Z',
      updatedAt: '2025-01-24T16:50:12.543Z',
    },
    {
      id: '0123456789012',
      userId: '2000012901234',
      templateId: '2226221967890',
      enrollmentId: '951',
      content: "🔔 New comment on your post: 'Great job on this project!'",
      status: 'SENT',
      channel: 'IN_APP',
      actions: {
        userId: '2000012901234',
        userName: 'Sophia Martinez',
      },
      type: 'real-time',
      actionType: 'comment',
      readAt: null,
      clickedAt: null,
      expiresAt: null,
      createdAt: '2025-01-24T17:55:20.456Z',
      updatedAt: '2025-01-24T17:55:20.876Z',
    },
  ];

  fill = 'btn btn-grad-ff ms-2';
  outline = 'btn btn-secondary-outline ms-2';

  buttonConfig = {
    invitation: [
      {
        label: 'Accept',
        action: 'acceptInvitation',
        style: this.fill,
      },
      {
        label: 'Cancel',
        action: 'cancelAction',
        style: this.outline,
      },
    ],
    update: [
      {
        label: 'Update',
        action: 'updateDetails',
        style: 'btn btn-grad-ff ms-2',
      },
      {
        label: 'Cancel',
        action: 'cancelAction',
        style: 'btn btn-secondary-outline ms-2',
      },
    ],
    reward: [
      {
        label: 'View Badge',
        action: 'viewBadge',
        style: 'btn btn-grad-ff ms-2',
      },
    ],
    session_timeout: [
      {
        label: 'Extend Session',
        action: 'extendSession',
        style: 'btn btn-grad-ff ms-2',
      },
      {
        label: 'Logout',
        action: 'logout',
        style: 'btn btn-secondary-outline ms-2',
      },
    ],
    payment_failure: [
      {
        label: 'Retry Payment',
        action: 'retryPayment',
        style: 'btn btn-grad-ff ms-2',
      },
      {
        label: 'Cancel',
        action: 'cancelAction',
        style: 'btn btn-secondary-outline ms-2',
      },
    ],
    appointment: [
      {
        label: 'Reschedule',
        action: 'rescheduleAppointment',
        style: 'btn btn-grad-ff ms-2',
      },
      {
        label: 'Cancel',
        action: 'cancelAppointment',
        style: 'btn btn-secondary-outline ms-2',
      },
    ],
    discount: [
      {
        label: 'Claim Offer',
        action: 'claimOffer',
        style: 'btn btn-grad-ff ms-2',
      },
      {
        label: 'Ignore',
        action: 'ignoreOffer',
        style: 'btn btn-secondary-outline ms-2',
      },
    ],
    comment: [
      {
        label: 'View Comment',
        action: 'viewComment',
        style: 'btn btn-grad-ff ms-2',
      },
    ],
  };

  actionApiConfig : any = {
    acceptInvitation: ApiEndPoints.acceptInvite,
    cancel: ApiEndPoints.algoAccount,
    invest : ApiEndPoints.algoPayment,
    acceptPaymenty: ApiEndPoints.algoPayment
  };
  constructor(private apiHelper: ApiHelper, private router: Router) {}

  ngOnInit() {
    this.getNotificationList();
  }

  getButtons(actionType: string) {
    return (this.buttonConfig as any)[actionType] || [];
  }
  handleButtonClick(action: string, notificationId: string) {
    console.log(`Action: ${action}, Notification ID: ${notificationId}`);
    // this.apiHelper
    //   .post({}, this.actionApiConfig[action])
    //   .subscribe((response) => {
    //     if (!this.isEmpty(response.data)) {
    //       this.notification.push(...response.data);
    //       this.checkIfEmpty = false;
    //     }
    //   });
  }

  backToProfileList() {
    this.router.navigate(['view']);
  }

  // rewrite
  getNotificationList() {
    this.apiHelper
      .post({}, ApiEndPoints.notificationFind)
      .subscribe((response) => {
        if (!this.isEmpty(response.data)) {
          this.notification.push(...response.data);
          this.checkIfEmpty = false;
        }
      });
  }

  isEmpty(data: any): boolean {
    if (!data || !Array.isArray(data)) {
      return true;
    }
    return data.length === 0;
  }

  invitationAccept(id: string) {
    this.apiHelper
      .post({ enrollmentId: id }, ApiEndPoints.acceptInvite)
      .subscribe((response) => {
        this.notification = this.notification.map((item: any) => {
          if (item.entrollmentId === id) {
            return response.data;
          }
          return item;
        });
      });
  }

  invitationCancel(id: string) {
    this.apiHelper
      .post({ enrollmentId: id }, ApiEndPoints.declineInvite)
      .subscribe((response) => {
        this.notification = this.notification.map((item: any) => {
          if (item.entrollmentId === id) {
            return response.data;
          }
          return item;
        });
      });
  }

  goToProject(projectId: string) {
    // this.router.navigate([`/view/projects/details/${projectId}`]);
  }
}
