

  <ngx-spinner *ngIf="isLoading" bdColor="rgba(0, 0, 0, 0.8)" 
             size="medium" 
             color="blue" 
             type="ball-clip-rotate-pulse" 
             [fullScreen]="true">
  <p style="color: white;">{{ loadingMessage }}</p>
</ngx-spinner>

