import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ProfileService } from 'src/app/modules/profile/services/profile.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  
  constructor(
    private router: Router,
    private apiHelper: ApiHelper,
    private toastrService : ToastrService,
    private profileService : ProfileService
  ) {
    
  }
  
  profile !: any;
  userSession !: any;

  ngOnInit(): void {
    let sessionInLocalStorage: any = localStorage.getItem('session')
    let session = JSON.parse(sessionInLocalStorage)
    this.getProfileRecord(session.profileId)
    
  }


  getProfileRecord(id : string){
    this.apiHelper.post({ id: id}, ApiEndPoints.profileGet).subscribe((response) => {
      this.userSession = response.data

    },
      (error) => {
        this.toastrService.error(error.error.message);
        // You can show an error message to the user here
      })
  }

  signOut() {
    localStorage.removeItem('session')
    localStorage.removeItem('profile')
    localStorage.removeItem('currentUser')
    this.router.navigate(['/login'])

  }

  viewProfile(arg0: boolean , id : string ) {

let a = {
  bool : arg0,
  id : id
}

this.profileService.setUserData(a)
this.router.navigate(['view/profile/view',a.id])

  }
}
