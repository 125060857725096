export const languages: string[] = [
  'English',
  'Hindi',
  'Tamil',
  'Telugu',
  'Kannada',
  'Malayalam',
  'Bengali',
  'Marathi',
  'Gujarati',
  'Punjabi',
  'Odia',
  'Assamese',
  'Bhojpuri',
  'Rajasthani',
  'Konkani',
  'Sanskrit',
  'Maithili',
  'Tulu',
  'Manipuri',
  'Nagpuri',
  'Chhattisgarhi',
  'Santali',
  'Dogri',
  'Khasi',
  'Sindhi',
  'Mizo',
  'Ladakhi',
];
export const roleLists: string[] = [
  'Director',
  'Producer',
  'Screenwriter',
  'Actor/Actress',
  'Associate Director',
  'Chief Associate Director',
  'Cinematographer',
  'Editor',
  'Sound Designer',
  'Composer/Music Director',
  'Production Designer',
  'Costume Designer',
  'Makeup Artist',
  'Visual Effects (VFX) Artist',
  'Stunt Coordinator',
  'Assistant Director',
  'Casting Director',
  'Production Manager',
  'Art Director',
  'Choreographer',
  'Colorist',
  'Animator',
  'Production Assistant',
  'SFX Makeup Artist',
  'Script Supervisor',
  'Sound Mixer',
  'Line Producer',
  'Prop Master',
  'Investor',
  'Motion Graphics Designer',
  "Writer"
];
