import { Component, Inject, inject, Input, OnInit } from '@angular/core';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ApiEndPoints } from 'src/app/core/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileEditComponent } from '../profile-edit/profile-edit.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { languages, roleLists } from 'src/app/core/datas';
import { SortingService } from 'src/app/core/service/sorting.service';
import { TaskService } from 'src/app/core/service/task.service';
import { ImageCropComponent } from 'src/app/shared/components/image-crop/image-crop.component';
import { dataformatorService } from 'src/app/core/service/dataformator.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { ImageViewerComponent } from 'src/app/shared/components/image-viewer/image-viewer.component';


@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss'],
})
export class ProfileViewComponent implements OnInit {
  imageChangedEvent: any;
  croppedImage: any;
  userDetails: any;
  hide!: boolean;
  hideArray = ['email'];

  canEdit: boolean = false;
  dialougBoxConfig: any;

  recordDetails: any;
  fileUpload: FormGroup;
  spinner: boolean = false;

  addForm: FormGroup;

  recordAddInfo: any = {
    courseAdd: false,
    projectAdd: false,
    achievementAdd: false,
    networkAdd: false,
    languageAdd: false,
    roleAdd: false,
    recommendationAdd: false,
  };

  recordInfo: any = {};
  updateProfile: FormGroup;
  profileEdit: boolean = false;
  session: any;
  currentEditCourse: any;

  config: any = {
    industryRole: {
      selectArray: [],
      formControlNames: 'industryRole',
    },
    languages: {
      selectArray: [],
      formControlNames: 'languages',
    },
  };

  profileId: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiHelper: ApiHelper,
    private activateRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    // public nxtDailog : Dialog ,
    private formBuilder: FormBuilder,
    private sorting: SortingService,
    private taskService: TaskService,
    public dataFormator: dataformatorService
  ) {
    this.userDetails = {};
    this.canEdit = false;
    this.fileUpload = this.formBuilder.group({
      file: [null],
    });

    this.addForm = this.editForm();

    this.updateProfile = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      email: [''],
      gender: [''],
      socialMediaLinks: [''],
      bio: [''],
      location: this.formBuilder.group({
        city: [''],
        country: [''],
      }),
      industryRole: [],
      languages: [],
    });
  }

  languages = this.sorting.sortArrayAlphabetically(languages);
  roleLists = this.sorting.sortArrayAlphabetically(roleLists);

  editForm() {
    return this.formBuilder.group({
      profileId: [null],
      string1: [null],
      string2: [null],
      string3: [null],
      string4: [null],
      date1: [null],
      date2: [null],
      name: [null],
      text1: [null],
      json1: [null],
    });
  }

  pop: boolean = false;

  ngOnInit(): void {
    this.activateRoute.params.subscribe((params) => {
      this.canEdit = false;
      let id = params['id'];

      this.apiHelper
        .post({ id: id }, ApiEndPoints.profileGet)
        .subscribe((response) => {
          this.userDetails = response.data;
          this.config['languages'].selectArray =
            this.userDetails.languages || [];

          this.config['industryRole'].selectArray =
            this.userDetails.industryRole || [];

          this.recordDetails = this.userDetails.records.map((item: any) => ({
            ...item,
            isEditMode: false,
          }));
          this.sortByDate(this.recordDetails);
          this.hide = this.checkForVisibiliy('email');
          this.recordInfo = this.categorizeByKey(this.recordDetails, 'name');
        });

      let userSessionString: any = localStorage.getItem('session');
      this.session = JSON.parse(userSessionString);
      this.profileId = this.session.profileId;
      if (id == this.session.profileId) {
        this.canEdit = true;
      }
    });
  }

  signOut() {
    localStorage.removeItem('session');
    localStorage.removeItem('profile');
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
  }

  updateProfilePic(event: any) {
    let input = event.target.files[0];
    let dialogRef = this.dialog.open(ImageCropComponent, {
      data: { file: input },
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.croppedImage = result;

      const formdata = new FormData();
      formdata.append('file', this.croppedImage);
      this.spinner = true;
      setTimeout(() => {
        this.apiHelper
          .post(formdata, ApiEndPoints.profileEdit)
          .subscribe((response) => {
            this.userDetails = response.data;
            this.taskService.updateUserDetails(response.data);

            this.spinner = false;
          });
      }, 100);
      event.target.value = null;
    });
  }

  onCancelClick() {
    if (this.currentEditCourse) {
      this.currentEditCourse.isEditMode = false;
      this.currentEditCourse = null;
      this.addForm.reset({});
    }
  }

  onAddSubmit(recordName: string, record: string) {
    if (!this.addForm.dirty) {
      return alert('no value has been added');
    }

    this.addForm.get('name')?.setValue(recordName);
    this.apiHelper
      .post(this.addForm.value, ApiEndPoints.profileRecordCreate)
      .subscribe((response) => {
        if (!this.recordInfo[recordName]) {
          this.recordInfo[recordName] = [];
        }
        this.recordInfo[recordName].push(response.data);
      });
    this.recordAddInfo[record] = false;
    this.addForm.reset({});
  }

  onEditSubmit(id: string, recordName: string) {
    this.addForm.get('name')?.setValue(recordName);
    this.apiHelper
      .post(
        { id: id, data: this.addForm.value },
        ApiEndPoints.profileRecordUpdate
      )
      .subscribe((response) => {
        if (!this.recordInfo[recordName]) {
          this.recordInfo[recordName] = [];
        }
        let recordeInfoIndex = this.recordInfo[recordName].findIndex(
          (item: any) => item.id === id
        );

        if (recordeInfoIndex !== -1) {
          this.recordInfo[recordName][recordeInfoIndex] = response.data;
          this.recordInfo[recordName][recordeInfoIndex].isEditMode = false;
        }
      });
  }

  formatDateV2(date: any) {
    let newDate = new Date(date);
    return newDate.toJSON().split('T')[0];
  }

  formatDateToMonth(date: any) {
    let newDate = new Date(date);
    return newDate.toISOString().slice(0, 7);
  }

  onEditClick(course: any) {
    this.onCancelClick();
    this.recordAddInfo = {
      courseAdd: false,
      projectAdd: false,
      achievementAdd: false,
      networkAdd: false,
      languageAdd: false,
      roleAdd: false,
      recommendationAdd: false,
    };
    this.currentEditCourse = course;
    this.currentEditCourse.isEditMode = true;

    if (this.currentEditCourse.isEditMode) {
      this.addForm.patchValue(this.currentEditCourse);
      this.addForm
        .get('date1')
        ?.patchValue(this.formatDateToMonth(this.currentEditCourse.date1));
      this.addForm
        .get('date2')
        ?.patchValue(this.formatDateToMonth(this.currentEditCourse.date2));
    }
  }

  categorizeByKey(objectArray: any[], key: string) {
    return objectArray.reduce((result: any, currentItem: any) => {
      const category = currentItem[key];

      if (!result[category]) {
        result[category] = [];
      }

      result[category].push(currentItem);
      return result;
    }, {});
  }

  sortByDate(recordDetails: any) {
    recordDetails.sort(
      (a: any, b: any) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );
  }

  formatDate(isoString: Date) {
    const date = new Date(isoString);
    let options: {} = {
      year: 'numeric',
      month: 'short',
    };

    return date.toLocaleDateString('en-US', options);
  }

  onToggle(condition: string) {
    if (this.currentEditCourse) {
      this.currentEditCourse.isEditMode = false;
    }
    this.currentEditCourse = null;

    this.addForm.reset({});
    this.recordAddInfo[condition] = !this.recordAddInfo[condition];
  }

  onSave() {
    this.apiHelper
      .post(this.updateProfile.value, ApiEndPoints.profileEdit)
      .subscribe((response) => {
        this.userDetails = response.data;
        this.profileEdit = !this.profileEdit;
      });
  }

  onProfileToggle(value: boolean) {
    this.profileEdit = !value;
    if (this.profileEdit) {
      this.updateProfile.patchValue(this.userDetails);
    }else{
       this.updateProfile.reset()
    }

  }

  responseDetail: any;
  recommendationAdd() {
    this.apiHelper
      .post({ id: this.session.profileId }, ApiEndPoints.profileGet)
      .subscribe((response) => {
        this.responseDetail = response.data;
        this.addForm.get('profileId')?.setValue(this.userDetails.id);
        this.addForm.get('json1')?.setValue({
          id: this.responseDetail.id,
          name: this.responseDetail.name,
          pictureFileLocations: this.responseDetail.pictureFileLocations,
        });
        this.onAddSubmit('recommendation', 'recommendationAdd');
      });
  }

  onDeleteClick(id: string, recordName: string) {
    this.apiHelper.post({ id }, ApiEndPoints.profileRecordDelete).subscribe(
      (response: any) => {
        this.recordInfo[recordName] = this.recordInfo[recordName].filter(
          (item: any) => item.id !== id
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }

  toggle(value: string) {
    this.apiHelper
      .post(
        {
          hiddenFields: value,
          visiblity: !this.hide,
        },
        ApiEndPoints.profileVisible
      )
      .subscribe((response: any) => {
        this.userDetails = response.data;
        this.hide = this.checkForVisibiliy(value);
      });
  }

  checkForVisibiliy(value: string) {
    if (this.userDetails?.hiddenFields?.includes(value)) {
      return false;
    }
    return true;
  }

  visibility(value: string): boolean {
    if (!this.canEdit) {
      return this.checkForVisibiliy(value);
    }
    return true;
  }

  onItemSelect(event: any, type: string) {
    const configs = this.config[type];
    const selectedItem = event.target.value;
    if (selectedItem && !configs['selectArray'].includes(selectedItem)) {
      configs['selectArray'].push(selectedItem);
      this.updateProfile
      .get(configs['formControlNames'])
      ?.setValue(configs['selectArray']);
    }
    event.target.value = '';
  }

  removeItem(item: string, type: string) {
    const configs = this.config[type];
    configs['selectArray'] = configs['selectArray'].filter(
      (r: any) => r !== item
    );
    this.updateProfile
      .get(configs['formControlNames'])
      ?.setValue(configs['selectArray']);
  }

  isItemSelected(item: string, type: string): boolean {
    const configs = this.config[type];
    return configs['selectArray'].includes(item);
  }

  removeImage() {
    this.apiHelper
      .post({ pictureFileLocations: null }, ApiEndPoints.profileEdit, true)
      .subscribe((response: any) => {
        this.userDetails = response.data 
      });
  }

  viewProfilePic(imagegeUrl: any) {

    if (imagegeUrl) {
      this.dialog.open(ImageViewerComponent, {
        data: { file: imagegeUrl },
        width: '500px',
        height: 'auto',
        backdropClass: 'modal-popup',
      });
    }
    return
  }

 handleKeydown(event: KeyboardEvent, key: string) {
    if (event.key.length === 1) {
      event.preventDefault();
    }
  }


}
