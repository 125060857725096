<div class="brand-sidebar">
  <div class="brand-sidebar__logo" [routerLink]="['/main/admin-landing']" routerLinkActive="router-link-active" >
    <img class="brand-sidebar__small-logo" src="/assets/images/img/logo.png" width="50px" alt="logo">
    <img class="brand-sidebar__full-logo" src="/assets/images/img/logo.png" width="100px" alt="full logo">
  </div>

  <div class="brand-sidebar__main-menu">
    <ul class="brand-sidebar__side-menu">
      <li class="brand-sidebar__side-item" *ngFor="let item of sidebarData">
        <div class="brand-sidebar__item" routerLink="{{item.url}}" routerLinkActive="router-link-active" > <img class="brand-sidebar__menu-icon" src="{{item.icon}}" alt="">
          <span class="brand-sidebar__side-menu-label">{{item.title}}</span>
        </div>
      </li>
    </ul>
  </div>
</div>
