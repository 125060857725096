import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGaurd implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  getUserInfo(): any {
    
    return JSON.parse(localStorage.getItem('currentUser') || '{}');
  }

  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean {

    if (this.authService.isAdminCheck()) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
