import { Component } from '@angular/core';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss'],
})
export class TransactionComponent {
  selectedTransaction: any[] = [];
  transactionDetail: any;
  tableHeadings = [
    'No',
    'Project Name',
    'Asset Id',
    'Budget',
    'Fund Invested',
    'Transaction Details',
    'Created On',
  ];
  constructor(private apiHelper: ApiHelper) {}

  ngOnInit() {
    this.apiHelper
      .post({}, ApiEndPoints.transactionFind, true)
      .subscribe((response) => {
        this.transactionDetail = response.data;

      });
  }

  getProjectDetail(projectId: any, value: string) {
    let project = this.transactionDetail.investedProjectDetails.find(
      (item: any) =>  item.id === projectId);
    return project[value];
  }


  viewDetails(transactionDetails: any[],drawer: any) {
    this.selectedTransaction = transactionDetails;
      (drawer).toggle();
    }
}
