import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  userDetails = new BehaviorSubject({});

  currentUserDetails = this.userDetails.asObservable();

  updateUserDetails(newQuote: string) {
    this.userDetails.next(newQuote);
  }
}
