import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-notification-view',
  templateUrl: './notification-view.component.html',
  styleUrls: ['./notification-view.component.scss']
})
export class NotificationViewComponent {
  notification: any = [];
  data: any = [];

  constructor(
    private apiHelper: ApiHelper,
    private router: Router,
  ) {}

  ngOnInit() {
    this.getNotificationList();
  }

  backToProfileList() {
    this.router.navigate(['view']);
  }

  getNotificationList() {
    this.apiHelper
      .post({}, ApiEndPoints.notificationFind)
      .subscribe((response) => {
        this.notification.push(...response.data);
      });
  }

  invitationAccept(id: string) {
    this.apiHelper
      .post({ enrollmentId: id }, ApiEndPoints.acceptInvite)
      .subscribe((response) => {
        this.notification = this.notification.map((item: any) => {
          if (item.entrollmentId === id) {
            return response.data;
          }
          return item;
        });
      });
  }

  invitationCancel(id: string) {
    this.apiHelper
      .post({ enrollmentId: id }, ApiEndPoints.declineInvite)
      .subscribe((response) => {
        this.notification = this.notification.map((item: any) => {
          if (item.entrollmentId === id) {
            return response.data;
          }
          return item;
        });
      });
  }

  goToProject(projectId: string) {
    this.router.navigate([`/view/projects/details/${projectId}`]);
  }
}