import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullComponent } from './shared/layout/full/full.component';
import { AuthGuard } from './core/guard/auth.guard';
import { AdminGaurd } from './core/guard/admin.guard';
import { LoginPageComponent } from './modules/login/pages/login-page/login-page.component';

import { ProfileListComponent } from './modules/profile/profile-list/profile-list.component';
import { otpCheckGuard } from './core/guard/otp-check.guard';
import { ProjectListComponent } from './modules/project/pages/project-list/project-list.component';
import { ResetPasswordComponent } from './modules/login/pages/reset-password/reset-password.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { ProfileViewComponent } from './modules/profile/profile-view/profile-view.component';
import { VendorListComponent } from './modules/vendor/vendor-list/vendor-list.component';
import { NotificationViewComponent } from './modules/notifcation/notification-view/notification-view.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/view',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'resetPassword/:token',
    component: ResetPasswordComponent,
    canActivate: [otpCheckGuard],
  },
  {
    path: 'admin',
    component: FullComponent,
    canActivate: [AuthGuard, AdminGaurd],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/admin/admin.module').then((m) => m.AdminModule),
      },
    ],
  },
  {
    path: 'view',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProfileListComponent,
      },
      {
        path: 'view/:id',
        component: ProfileViewComponent,
      },
      {
        path: 'notifications',
        component: NotificationViewComponent,
      },
      {
        path: 'projects',
        component: ProjectListComponent,
      },
      {
        path: '',
        loadChildren: () =>
          import('./modules/project/project.module').then(
            (m) => m.ProjectModule
          ),
      },
      {
        path: 'vendor',
        component: VendorListComponent,
      },
      {
        path: '',
        loadChildren: () =>
          import('./modules/vendor/vendor.module').then((m) => m.VendorModule),
      },

      {
        path: 'profile',
        loadChildren: () =>
          import('./modules/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
