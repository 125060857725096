declare let google: any;

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { AuthService } from 'src/app/core/guard/auth.service';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { environment } from 'src/environments/environment';
import { defaultRules, validateFields } from 'src/app/shared/components/field-validation/validation-service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  loginForm: FormGroup;
  signUpForm: FormGroup;
  loginAccount: boolean = true;
  signinAccount: boolean = false;
  googleSignInScriptLoaded: boolean = false;
  isGoogleLoginValid: boolean = true;
  defaultRules = defaultRules;

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private router: Router,
    private authService: AuthService,
    private cookieService: CookieService,
    private apiHelper: ApiHelper
  ) {
    this.loginForm = this.formBuilder.group({
      phoneNumber: ['', [Validators.required]],
      password: ['', Validators.required],
    });

    this.signUpForm = this.formBuilder.group({
      phoneNumber: ['', Validators.required],
      password: ['', Validators.required],
      email:['',Validators.required],
      confirmPassword: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    const agent = window.navigator.userAgent;
    if (this.checkIsInAppBrowser(agent)) {
      this.isGoogleLoginValid = false;
    }

    this.loadGoogleSignInScript();
    this.checkSession();
  }

   get passwordErrorMessage(): string | null {
    const passwordControl = this.signUpForm.get('password');
    if (passwordControl?.hasError('customError')) {
      return passwordControl.errors?.['customError'];
    }
    return null;
  }

  checkIsInAppBrowser(agent: string): boolean {
    const patterns = [
      /FBAN|FBAV/i,
      /Instagram/i,
      /Twitter/i,
      /LinkedInApp/i,
      /Snapchat/i,
      /GSA/i,
      /Line/i,
      /WhatsApp/i,
      /Telegram/i,
      /Pinterest/i,
      /Reddit/i,
      /Outlook/i,
    ];

    return patterns.some((pattern) => pattern.test(agent));
  }

  accountChange(): void {
    this.signinAccount = !this.signinAccount;
    this.loginAccount = !this.loginAccount;
  }

  onLoginSubmit(): void {

    if (this.loginForm.invalid) {
      return;
    }

    const credentials = {
      phoneNumber: this.loginForm.value.phoneNumber,
      password: this.loginForm.value.password,
    };

    this.loginService(credentials, ApiEndPoints.signIn);
  }

  onSignUpSubmit(): void {
  
    Object.keys(this.signUpForm.controls).forEach((key) => {
      const control = this.signUpForm.get(key);
      control?.setErrors(null);
    });

    const validationErrors = validateFields(this.signUpForm.value);
  
    if (Object.keys(validationErrors).length > 0) {
      for (const field in validationErrors) {
        const control = this.signUpForm.get(field);
        if (control) {
          control.setErrors({ customError: validationErrors[field] });
        }
      }
      return;
    }
  
    if (this.signUpForm.invalid) {
      return;
    }

    const signUpCredentials = {
      phoneNumber: this.signUpForm.value.phoneNumber,
      password: this.signUpForm.value.password,
      email:this.signUpForm.value.email,
      confirmPassword: this.signUpForm.value.confirmPassword,
      firstName: this.signUpForm.value.firstName,
      lastName: this.signUpForm.value.lastName,
    };
  
    this.loginService(signUpCredentials, ApiEndPoints.signUp);
  }
  

  loginService(data: any, apiEndPoint: string): void {
    this.apiHelper.post(data, apiEndPoint, false).subscribe(
      (response) => {
        const { session, profile } = response.data;

        localStorage.setItem('session', JSON.stringify(session));
        localStorage.setItem('profile', JSON.stringify(profile));
        localStorage.setItem(
          'currentUser',
          JSON.stringify({ loginVal: 'logged' })
        );

        this.authService.running = true;
        if (this.authService.running) {
          this.router.navigate(['/view']);
        }
      },
      (error) => {
        this.toastrService.error(error.error.message);
      }
    );
  }

  loadGoogleSignInScript(): void {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = false;
    script.defer = false;
    script.onload = () => {
      this.googleSignInScriptLoaded = true;
      this.initializeGoogleSignIn();
    };
    document.body.appendChild(script);
  }

  initializeGoogleSignIn(): void {
    if (
      typeof google !== 'undefined' &&
      google.accounts &&
      google.accounts.id
    ) {
      google.accounts.id.initialize({
        client_id: environment.googleClientId,
        callback: this.handleCredentialResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true,
        prompt: 'select_account',
      });

      google.accounts.id.renderButton(document.getElementById('buttonDiv')!, {
        theme: 'filled_blue',
        size: 'large',
        shape: 'rectangular',
        width: 50,
        logo_alignment: 'center',
      });
    }
  }

  handleCredentialResponse(resp: any): void {
    const data = {
      credential: resp.credential,
    };

    this.loginService(data, ApiEndPoints.emailSignUP);
  }

  setCookieValue(value: string): void {
    this.cookieService.set('myCookie', value);
  }

  private checkSession(): void {
    const sessionString = localStorage.getItem('session');
    if (!sessionString) {
      return;
    }

    this.apiHelper.post({}, ApiEndPoints.checkAuth).subscribe(
      () => {
        this.router.navigate(['/view']);
      },
      () => {
        this.toastrService.error('Your session has expired, please login');
      }
    );
  }
}
