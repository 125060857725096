import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimText'
})
export class TrimTextPipe implements PipeTransform {

  transform(text: String, characterCount: number): unknown {
    let outputText = text;
    let textlength =text?.trim().length
    if (text && textlength != 0 && textlength>characterCount ) {
      outputText = outputText.slice(0, characterCount).concat("...");
    }
    return outputText;
  }

}
