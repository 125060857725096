<!-- <div class="jumbotron-custom">
  <div class="container">
    <h1 class="title1-highlighted">Invest And <span class="title1-gradient">Create</span></h1>
  <h4 class="title4-dim">Discover and invest in visionary film projects, watch them come to life, and be part of cinematic
    success stories. Empower creativity, connect with top talent, and help shape the future of film.</h4>
    <div class="mt-4">
      <div class="d-inline-block" *ngIf="!isChecked">
        <button class="btn btn-grad-ff mb-2 mx-2 btn-lg" (click)="onButtonSwitch('asset' ,'true')">Invest on Projects</button>
      </div>
      <div class="d-inline-block" *ngIf="isChecked">
        <button class="btn btn-grad-ff mb-2 mx-2 btn-lg" (click)="onButtonSwitch('asset' ,'')">View Projects</button>
      </div>
      <div class="d-inline-block"><button class="btn btn-grad-ff mb-2 mx-2 btn-lg" [routerLink]="['/view/project/create']">Add Project</button></div>
    </div>
  </div>
</div> -->
<!-- <div class="jumbotron-custom">
  <div class="container">
    <h1 class="title1-highlighted">Invest And <span class="title1-gradient">Create</span></h1>
  <h4 class="title4-dim">Discover and invest in visionary film projects, watch them come to life, and be part of cinematic
    success stories. Empower creativity, connect with top talent, and help shape the future of film.</h4>
    <div class="mt-4">
      <div class="d-inline-block" *ngIf="!isChecked">
        <button class="btn btn-grad-ff mb-2 mx-2 btn-lg" (click)="onButtonSwitch('asset' ,'true')">Invest on Projects</button>
      </div>
      <div class="d-inline-block" *ngIf="isChecked">
        <button class="btn btn-grad-ff mb-2 mx-2 btn-lg" (click)="onButtonSwitch('asset' ,'')">View Projects</button>
      </div>
      <div class="d-inline-block"><button class="btn btn-grad-ff mb-2 mx-2 btn-lg" [routerLink]="['/view/project/create']">Add Project</button></div>
    </div>
  </div>
</div> -->
<div class="pad-t container">

  
  <div class="d-inline-block"><button class="btn btn-grad-ff mb-2 mx-2 btn-lg" [routerLink]="['/view/project/create']">Add Project</button></div>
  
</div>

<div class="search-filter-wrap">
  <div class="d-flex justify-content-center flex-wrap">
    <div class="filter-item">
      <input type="search" placeholder="Search" aria-label="Search" #searchSelect class="form-control form-control-lg"
        (input)="onSearchActive( 'freeText',$event)">
    </div>
    <!-- <div class="filter-item">
      <select class="form-select form-select-lg">
        <option value="">Role</option>
        <option value="">Role 2</option>b
        <option value="">Role 3</option>
        <option value="">Role 4</option>
      </select>
    </div> -->
    <!-- <div class="filter-item">
      <div class="checkbox-wrap">
        <div class="form-check">
          <input class="form-check-input" #checkSelect type="checkbox" value="" id="defaultCheck1"
            (change)="onSearchActive( 'asset',$event)">
          <label class="form-check-label" for="defaultCheck1">
            Invested
          </label>
        </div>
      </div>
    </div> -->
    <!-- <div class="filter-item">
      <select class="form-select form-select-lg">
        <option value="">Film</option>
        <option value="">Role 2</option>
        <option value="">Role 3</option>
        <option value="">Role 4</option>
      </select>
    </div> -->
    <div class="filter-item">
      <button class="btn btn-grad-ff" (click)="onCancel(false , searchSelect)">Clear</button>
    </div>
  </div>
</div>



<div class="container-lg mt-5">
  <div class="row">
    <div class="col-lg-3 col-md-6 col-sm-12 col-xl-5col mb-3" *ngFor="let item of projects"
      [routerLink]="['details', item.id]">
      <div class="card-wrap card-wrap--hover rounded">
        <div class="profile-project">
          <img class="" [src]="item.pictureFileLocations | imagesource:'project'" alt="project Image" />
        </div>
        <div class="card-detail">
          <h5 class="title5-highlighted custom-tooltip-ff" [attr.data-tooltip]="item.name.length > 30 ? formatTitle(item.name,30) : null">
            {{ item.name | trimText: 30 }}
          </h5>
          <h6 class="title6-highlighted">{{item.movieType}}</h6>
          <h6 class="title6-dim custom-tooltip-ff" [attr.data-tooltip]="item.description.length > 80 ? formatTitle(item.description, 80) : null">
            {{ item.description | trimText:80 }}
          </h6>

        </div>
      </div>

    </div>
  </div>
</div>