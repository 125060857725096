<h2>Please crop your image</h2>
<mat-dialog-content>
  <!-- <image-cropper
    [maintainAspectRatio]="true"
    [onlyScaleDown]="true"
    format="png"
    [imageFile]="data.file"
    [cropperStaticWidth]="246"
    [cropperStaticHeight]="437.33"
    (imageCropped)="imageCropped($event)"
  > -->
  <image-cropper
    [maintainAspectRatio]="true"
    [onlyScaleDown]="true"
    format="png"
    [imageFile]="data.file"
    [aspectRatio] = "0.76/1"
    (imageCropped)="imageCropped($event)"
  >
  </image-cropper>
</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
  <button mat-button [mat-dialog-close]="result()" cdkFocusInitial>Done</button>
</div>
