<div class="smallHeader pad-t">
  <div class="container-lg">
    <div
      class="row py-3 py-md-4 px-2 px-md-4 align-items-center rounded"
      style="
        background-image: linear-gradient(
            205deg,
            rgba(189, 66, 255, 0.38) 16.1%,
            rgba(7, 31, 129, 0.38) 45.55%
          ),
          url(../../../../assets/images/pexels-photo.jpg);
        background-size: cover;
      "
    >
      <div class="col-md-6 point">
        <h1>Invest And Create</h1>
        <h4>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nemo
          pariatur quam ducimus nam consectetur explicabo velit voluptas eos.
          Esse libero assumenda excepturi eveniet hic commodi enim veniam
          tenetur expedita! Officiis!
        </h4>
        <div class="d-flex flex-wrap gap-2 mb-3 mb-md-0">
          <button class="btn btn-grad-ff text-white">Invest on Project</button>
          <button
            class="btn btn-grad-ff text-white"
            [routerLink]="['/view/project/create']"
          >
            Create Crowdfunding Campaign
          </button>
        </div>
      </div>
      <div class="col-md-6 text-center">
        <img
          src="../../../../assets/images/free-photo-of-orange-lens-in-the-dark.jpg"
          class="img-fluid rounded-circle w-50"
          style="max-width: 250px"
          alt=""
        />
      </div>
    </div>
  </div>
</div>

<div class="container-lg mt-5">
  <div class="row">
    <div
      class="col-lg-4 col-md-6 col-sm-12 col-xl-3 mb-3"
      *ngFor="let item of projects"
      [routerLink]="['details', item.id]"
    >
      <div class="card-wrap card-wrap--hover rounded pb-3">
        <div class="profile-cardPic">
          <img
            class=""
            [src]="item.pictureFileLocations"
            onerror="this.onerror=null;this.src='assets/images/user1.png';"
            alt="project Image"
          />
        </div>
        <div class="ms-4">
          <h3 class="mt-4 fw-bold text-capitalize text-white">
            {{ item.name }}
          </h3>
          <h5 class="text-white">{{ item.description }}</h5>
        </div>
      </div>
    </div>
  </div>
</div>
