<div class="dark:bg-transparent min-vh-100 d-flex flex-row align-items-center">
    <div class="container-md">
        <div class="justify-content-center row">
            <div class="col-lg-10 col-xl-8">
                <div class="card-group">
                    <div class="card text-white bg-primary py-5">
                        <div class="text-center card-body">
                            <div>
                                <img src="assets\images\pageNotFound.png" class="container-img" alt="">

                            </div>
                        </div>
                    </div>
                    <div class="card p-4">
                        <div class="card-body">
                            <div>
                                <form>
                                    <h1 class="text-dark">404: Lost in the Reels!</h1>
                                    <p>This page seems to have wandered off the script.</p>
                                    <div class="mb-4 input-group">
                                        <p>But the story's far from over! You can:</p>

                                    </div>
                                    <div class="row">
                                        <div class="col-6" >
                                            <button color="primary" class="px-4 btn btn-primary"  [routerLink]="['/view']">
                                                HOME PAGE
                                            </button>
                                        </div>
                                        <p>
                                            Return to the homepage and find your next big hit.
                                        </p>
                                        <div class="text-right col-6">
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>