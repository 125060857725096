<div class="authentication-wrap">
  <div class="logo">
    <img src="assets/images/img/ieelogowhite.webp" alt="logo" />
  </div>
  <div class="row">
    <div class="col-md-6 col-sm-12">
      <div class="login-img position-relative">
        <img
          class="img-fluid"
          src="assets/images/img/cameraSide-view.jpg"
          alt=""
        />
        <!-- <div class="overlay-text">
        <h1 class="title1-highlighted">Spotlight Your<span class="title1-gradient">Story!</span></h1>
        <h4 class="title4-dim">
          Create your profile with your personal journey, projects, achievements, and awards.
          Let the world see your work, connect with industry professionals, and unlock exclusive
          features that celebrate your unique cinematic path.
        </h4>
      </div> -->
      </div>
    </div>
    <div class="offset-md-1 col-md-4 offset-md-1 col-sm-12 login-rhs">
      <div class="login h-100">
        <div
          class="h-100 d-flex flex-column align-items-center justify-content-center"
        >
          <div class="login-form w-100 p-2 pb-2" *ngIf="loginAccount">
            <h1 class="title2-highlighted text-center mb-3">Login</h1>
            <div class="">
              <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
                <div class="mb-2">
                  <label for="" class="title-normal">Phone Number</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="phoneNumber"
                    placeholder="Phone Number"
                  />
                  <span
                    *ngIf="
                      loginForm.invalid &&
                      (loginForm.dirty || loginForm.touched)
                    "
                    class="text-danger"
                  >
                    <span *ngIf="loginForm.errors">This field is required</span>
                  </span>
                </div>
                <div class="mb-2">
                  <label for="" class="title-normal">Password</label>
                  <input
                    class="form-control"
                    type="password"
                    formControlName="password"
                    placeholder="Password"
                  />
                </div>
                <div class="mb-3 mt-4">
                  <p
                    class="title6-dim cursor-hand text-decoration-underline text-center"
                    (click)="accountChange()"
                  >
                    Don't have an account yet
                  </p>
                </div>
                <div class="mb-2 cursor-hand">
                  <div class="d-flex justify-content-center">
                    <button
                      type="submit"
                      class="btn w-100 btn-grad-ff btn-lg"
                      [disabled]="loginForm.invalid"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="login-form w-100 p-2 pb-2" *ngIf="signinAccount">
            <h1 class="title2-highlighted text-center mb-3">Sign Up</h1>
            <div class="">
              <form [formGroup]="signUpForm" (ngSubmit)="onSignUpSubmit()">
                <div class="row">
                  <div class="col-md-6 mb-2">
                    <label for="" class="title-normal">First Name</label>
                    <input
                      class="form-control"
                      type="text"
                      formControlName="firstName"
                      placeholder="First Name"
                    />
                  </div>
                  <div class="col-md-6 mb-2">
                    <label for="" class="title-normal">Last Name</label>
                    <input
                      class="form-control"
                      type="text"
                      formControlName="lastName"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div class="mb-2">
                  <label for="" class="title-normal">Email</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="email"
                    placeholder="Email"
                  />
                  <small
                    class="text-danger"
                    *ngIf="signUpForm.get('email')?.errors?.['customError']"
                  >
                    {{ signUpForm.get('email')?.errors?.['customError'] }}
                  </small>
                </div>
                <div class="mb-2">
                  <label for="" class="title-normal">Phone Number</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="phoneNumber"
                    placeholder="Phone Number"
                  />
                  <small
                    class="text-danger"
                    *ngIf="signUpForm.get('phoneNumber')?.errors?.['customError']"
                  >
                    {{ signUpForm.get('phoneNumber')?.errors?.['customError'] }}
                  </small>
                </div>
                <div class="mb-2">
                  <label
                    for=""
                    class="title-normal tooltip-container"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="*Must be at least 8 characters long, including at least one number"
                  >
                    Password*
                  </label>
                  <input
                    class="form-control"
                    type="password"
                    formControlName="password"
                    placeholder="Password"
                  />

                  <small
                    class="text-danger"
                    *ngIf="signUpForm.get('password')?.errors?.['customError']"
                  >
                    {{ passwordErrorMessage }}
                  </small>
                </div>

                <div class="mb-3">
                  <label for="" class="title-normal">Confirm Password</label>
                  <input
                    class="form-control"
                    type="password"
                    formControlName="confirmPassword"
                    placeholder="Confirm Password"
                  />
                </div>
                <div class="mb-2 mt-3">
                  <div class="d-flex justify-content-center">
                    <p
                      class="title6-dim cursor-hand text-decoration-underline text-center"
                      (click)="accountChange()"
                    >
                      Login Account
                    </p>
                  </div>
                </div>
                <div class="mb-2 cursor-hand">
                  <div class="d-flex justify-content-center">
                    <button
                      class="btn w-100 btn-grad-ff btn-lg"
                      type="submit"
                      [disabled]="signUpForm.invalid"
                    >
                      Signup
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div
            class="d-flex justify-content-center pb-5 w-100"
            *ngIf="isGoogleLoginValid"
          >
            <div id="buttonDiv"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="parent clearfix">
  <div class="bg-illustration">
    <img src="assets/images/img/ieelogowhite.webp" alt="logo" />
  </div>
  <div class="login">
    <div class="gradient-bg"></div>
    <div
      class="container height-100vh d-flex flex-column align-items-center justify-content-center p-4 pb-0"
    >
      <div
        class="login-form bg-app-primary2 w-100 p-5 pb-2"
        *ngIf="loginAccount"
      >
        <div class="">
          <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
            <div class="mb-2">
              <input
                class="form-control"
                type="text"
                formControlName="phoneNumber"
                placeholder="Phone Number"
              />
              <span
                *ngIf="
                  loginForm.invalid && (loginForm.dirty || loginForm.touched)
                "
                class="text-danger"
              >
                <span *ngIf="loginForm.errors">This field is required</span>
              </span>
            </div>
            <div class="mb-2">
              <input
                class="form-control"
                type="password"
                formControlName="password"
                placeholder="Password"
              />
            </div>
            <div class="mb-3">
              <div class="d-flex justify-content-center">
                <p
                  class="text-white cursor-hand font-md m-0"
                  (click)="accountChange()"
                >
                  Signup Account
                </p>
              </div>
            </div>
            <div class="mb-2 cursor-hand">
              <div class="d-flex justify-content-center">
                <button
                  type="submit"
                  class="btn btn-outline-dark text-white btn-grad-ff"
                  [disabled]="loginForm.invalid"
                >
                  Login
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        class="login-form bg-app-primary2 w-100 p-5 pb-2"
        *ngIf="signinAccount"
      >
        <div class="">
          <form [formGroup]="signUpForm" (ngSubmit)="onSignUpSubmit()">
            <div class="mb-2">
              <input
                class="form-control"
                type="text"
                formControlName="phoneNumber"
                placeholder="Mobile Number"
              />
            </div>
            <div class="mb-2">
              <input
                class="form-control"
                type="password"
                formControlName="password"
                placeholder="password"
              />
            </div>
            <div class="mb-2">
              <input
                class="form-control"
                type="password"
                formControlName="confirmPassword"
                placeholder="Confirm Password"
              />
            </div>
            <div class="row">
              <div class="col-md-6 mb-2">
                <input
                  class="form-control"
                  type="text"
                  formControlName="firstName"
                  placeholder="First Name"
                />
              </div>
              <div class="col-md-6 mb-2">
                <input
                  class="form-control"
                  type="text"
                  formControlName="lastName"
                  placeholder="Second Name"
                />
              </div>
            </div>

            <div class="mb-3">
              <div class="d-flex justify-content-center">
                <p
                  class="text-white cursor-hand font-md m-0"
                  (click)="accountChange()"
                >
                  Login Account
                </p>
              </div>
            </div>
            <div class="mb-2 cursor-hand">
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-outline-dark text-white btn-grad-ff"
                  type="submit"
                  [disabled]="signUpForm.invalid"
                >
                  Signup
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="d-flex justify-content-center bg-app-primary2 pb-5 w-100">
        <div id="buttonDiv"></div>
      </div>
    </div>
  </div>
</div> -->
