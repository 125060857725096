
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { SpinnerService } from 'src/app/core/service/spinner.service';


@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean = false; 
  @Input() loadingMessage: string = 'Loading...'; 

  constructor(private spinnerService: SpinnerService) {}

  ngOnInit() {
    if (this.isLoading) {
      this.spinnerService.show(); 
    }
  }

  ngOnChanges() {
    if (this.isLoading) {
      this.spinnerService.show(); 
    } else {
      this.spinnerService.hide(); 
    }
  }

  ngOnDestroy() {
    this.spinnerService.hide(); 
  }
}

