import { Component } from '@angular/core';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  notification: any = [];
  data: any = [];

  constructor(private apiHelper: ApiHelper) {}

  ngOnInit() {
    this.getNotificationList();
  }

  getNotificationList() {
    this.apiHelper
      .post({}, ApiEndPoints.notificationFind)
      .subscribe((response) => {
        this.notification.push(...response.data);
      });
  }

  invitationAccept(id: string) {
    this.apiHelper
      .post({ enrollmentId: id }, ApiEndPoints.acceptInvite)
      .subscribe((response) => {
        this.notification = this.notification.map((item: any) => {
          if (item.entrollmentId === id) {
            return response.data;
          }
          return item;
        });
      });
  }
}
