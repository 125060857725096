import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  focusOnList: boolean = true;
  searchQuery: string = '';
  searchResults: any[] = [];
  data: any = { items: [] };

  @Input()
  type!: string;

  @Output()
  newItemEvent = new EventEmitter<any>();

  @Output()
  searchResultList = new EventEmitter<any>();

  constructor(private apiHelper: ApiHelper, private router: Router) {}

  ngOnInit(): void {
    this.onSearchList();
  }

  onSearch(): void {
    if (this.searchQuery) {
      this.apiHelper
        .post({ filters: { freeText: this.searchQuery } }, ApiEndPoints.search)
        .subscribe(
          (response) => {
            this.searchResults = response.data.items;
            this.searchResultList.emit(this.searchResults);
          },
          (error) => {
            this.searchResults = [];
          }
        );
    } else {
      this.searchResults = [];
    }
  }

  onProfileClick(id: string) {
    this.searchQuery = '';
    this.searchResults = [];

    this.router.navigate(['view/profile/view', id]);
  }

  onInvite(data: any) {
    this.newItemEvent.emit(data);
  }

  onSearchList() {
    this.apiHelper
      .post(
        { filters: { freeText: this.searchQuery } },
        ApiEndPoints.customSearch
      )
      .subscribe(
        (response) => {
          this.searchResultList.emit(response.data.profileDetails);
        },
        (error) => {
          this.searchResults = [];
        }
      );
  }
}
