import { Component } from '@angular/core';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent {




  projects :any;
  constructor(
    private apiHelper : ApiHelper,
  ){

  }

  ngOnInit(): void {
    this.getProjects()
  }

  
  getProjects() {
    this.apiHelper.post( {} ,ApiEndPoints.projectGet ,true).subscribe((response)=>{
      this.projects = response.data
    })
    
  }
}
