// import { CanActivateFn } from '@angular/router';

// export const otpCheckGuard: CanActivateFn = (route, state) => {

//   return true;
// };
import {
  CanActivateFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { inject } from '@angular/core';
import { ApiHelper } from '../service/api.helper'; // Assuming you have a service to handle API calls
import { ApiEndPoints } from '../constants';

export const otpCheckGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): any => {
  const router = inject(Router);
  const apiHelper = inject(ApiHelper);

  const uniqueToken = route.params['token'];
  // if (!uniqueToken) {
  //   console.log('here');
  //   router.navigate(['/error']); // If no uniqueToken, redirect to error page
  //   return false; // Block the navigation
  // }

  const data = { uniqueToken: uniqueToken };
  let canActivate: boolean;
  apiHelper.post(data, ApiEndPoints.getToken, false).subscribe((response) => {
    const isLogged = response.data[0].isLogged;
    if (isLogged === 'true') {
      router.navigate(['/login']);
      return false;
    } else {
      return true;
    }
  });
};
