import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConfigCheckService {
  checkConfig(data: any, config: any) {
    for (const key in config) {
      const value = data[key];
      const checks = config[key];
      if (checks.coulmnToCheck === data[key]) {
        if (checks.valueChecker == 'nullChecker') {
          this.nullCheck(value);
        }
        if (checks.coulmnToCheck === data[key]) {
          if (checks.valueChecker == 'stringChecker') {
            this.stringCheck(value);
          }
        }
      }
    }
  }

  checkConfigV2(data: any, config: any) {
    for (const key in config) {
      let specificKeyValue = config[key];
      return this.checker(
        specificKeyValue.valueChecker,
        data[specificKeyValue.coulmnToCheck]
      );
    }
  }

  checker(toValidate: any, validateValue: any) {
    if (toValidate.includes('nullChecker')) {
      this.nullCheck(validateValue);
    }
    if (toValidate.includes('stringChecker')) {
      this.stringCheck(validateValue);
    }
  }

  nullCheck(value: any) : any{
    if (value === null) {
      return false;
    }
  }

  stringCheck(value: any) : any {
    if (value.trim().length === 0) {
      return false;
    }
  }
}
