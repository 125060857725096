
<mat-drawer-container class="sidenav-container card bg-app-secondary2" autosize>
        <div class="container-lg pad-t">
      <div class="example-sidenav-content">
      <div class="table-responsive table-high scrollbar2">
        <table class="table text-white text-capitalize table-common ">
          <thead>
            <tr>
              <th scope="col" *ngFor="let head of tableHeadings">{{ head }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-cfont-secondary2" *ngFor="let detail of transactionDetail?.userTxnDetails; let i = index;">
              <td>{{ i + 1 }}</td>
              <td>{{getProjectDetail(detail.projectId,'name')}}</td>
              <td>{{ detail.assetId }}</td>
              <td>{{getProjectDetail(detail.projectId,'budget' )}}</td>
              <td>{{ detail.paymentDetails.totalTransactedAlgo / 1000000 + ' Algo' }}</td>
              <td><a (click)="viewDetails(detail,drawer)">view details</a></td>
              <td>{{ detail.createdAt | date:'short' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
      
    </div>
    <mat-drawer #drawer class="sidenav-container card bg-app-secondary2 pad-t" mode="side" position="end">
      <div class="p-2">
      <h4 class="title4-highlighted">Payment Details</h4>
          <div class="title5-dim" *ngFor="let txn of selectedTransaction">
            {{txn}}
          </div>
        </div>
      </mat-drawer>
    </mat-drawer-container>
    <!-- <h5 class="title5-highlighted">{{ selectedTransaction?.totalTransactedAlgo / 1000000 }} Algo</h5>
     <h5 class="title5-dim">{{ txn.transactionDetails.assetCount }}</h5> -->
  
  
    <!-- <div class="example-sidenav-content">
      <button type="button" mat-button (click)="drawer.toggle()">
        Toggle sidenav
      </button>
    </div> -->
  
<!-- <div
  *ngIf="selectedTransaction.length > 0"
  class="modal"
  id="transaction"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content bg-app-primary">
      <div class="modal-body">
        <span class="close" (click)="selectedTransaction = []">&times;</span>
        <h3>Transaction Details</h3>
        <table class="table">
          <thead>
            <tr>
              <th>Asset Count</th>
              <th>Payment Transaction ID</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let txn of selectedTransaction">
              <td>{{ txn.assetCount }}</td>
              <td>{{ txn.paymentTxnId }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div> -->