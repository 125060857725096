
<mat-drawer-container class="sidenav-container card bg-app-secondary2" autosize>
        <div class="container-lg pad-t">
      <div class="example-sidenav-content">
      <div class="table-responsive table-high scrollbar2">
        <table class="table text-white text-capitalize table-common ">
          <thead>
            <tr>
              <th scope="col" *ngFor="let head of tableHeadings">{{ head }}</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-cfont-secondary2" *ngFor="let detail of transactionDetail?.userTxnDetails; let i = index;">
              <td>{{ i + 1 }}</td>
              <td>{{getProjectDetail(detail.projectId,'name')}}</td>
              <td>{{ detail.assetId }}</td>
              <td>{{getProjectDetail(detail.projectId,'budget' )}}</td>
              <td>{{ detail.paymentDetails.totalTransactedAlgo / 1000000 + ' Algo' }}</td>
              <td><a (click)="viewDetails(detail.transactionDetails,drawer)">view details</a></td>
              <td>{{ detail.createdAt | date:'short' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
      
    </div>
    <mat-drawer #drawer class="sidenav-container card bg-app-secondary2 pad-t" mode="side" position="end">
      <div class="p-2">
      <h4 class="title4-highlighted">Payment Details</h4>
          <div class="title5-dim" *ngFor="let txn of selectedTransaction; let i = index">
            {{i+1}}
         <h2>
         PaymentId: <a target="_blank" href="https://lora.algokit.io/testnet/transaction/{{txn.paymentTxnId}}">{{txn.paymentTxnId}}</a> 
        </h2>
         <h2>
         AssetTxnId: <a target="_blank" href="https://lora.algokit.io/testnet/transaction/{{txn.assetTxnId}}">{{txn.assetTxnId}}</a> 
        </h2>
          </div>
        </div>
      </mat-drawer>
    </mat-drawer-container>