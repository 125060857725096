import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  userData;
  userId;
    constructor(){
      this.userData = {};
      this.userId = ''
    }
    setUserData(val: object){
      this.userData= val;
    }
    setUserId(id: string){
      this.userData= id;
    }
    getUserData(){
      return this.userData;
    }
}
// "Poppins", sans-serif;