import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SortingService {
  sortArrayAlphabetically(arr: string[]): string[] {
    const uniqueWords = new Set<string>();
    return arr
      .filter((word) => {
        const normalizedWord = word.toLowerCase();
        if (uniqueWords.has(normalizedWord)) {
          return false; // Exclude duplicates
        }
        uniqueWords.add(normalizedWord);
        return true;
      })
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  }
}
