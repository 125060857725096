<div class="bg-app-primary">
  <h1 mat-dialog-title class="text-white">Notifications</h1>
  <div class="bg-app-primary pt-2" *ngFor="let item of notification" mat-dialog-content value="item">

    <div class="text-white">
      {{ item.message }}
    </div>
    <div class="ps-2" *ngIf="
        item.notificationAction == 'action' &&
        item.notificationType == 'invitation'
      ">
      <button class="btn btn-secondary-outline me-2">Cancel</button>
      <button class="btn btn-grad-ff" (click)="invitationAccept(item.entrollmentId)">
        Accept
      </button>
    </div>
    <div class="ps-2" *ngIf="
        item.notificationAction == 'action' &&
        item.notificationType == 'investment'
      ">
      <button class="btn btn-grad-ff">Invest</button>
    </div>
  </div>
</div>