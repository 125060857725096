<div class="container pad-t">
  <!-- profile -->
  <div class="card bg-app-secondary2 p-4">
    <div class="profileDetails p-1">
      <div class="row mb-3">
        <div class="d-flex justify-content-between">
          <div class="text-white anim-i" [routerLink]="['/view']">
            <i class="fa fa-solid fa-chevron-left pe-2"></i>
            <span>Profile View</span>
          </div>
          <div *ngIf="!profileEdit">
            <div class="anim-i" *ngIf="canEdit">
              <h3>
                <span mat-button [matMenuTriggerFor]="menu">
                  <i class="fas fa-ellipsis-v text-white"></i>
                  <mat-menu
                    #menu="matMenu"
                    class="input-field form-control bg-app-primary"
                  >
                    <button
                      mat-menu-item
                      class="text-white"
                      (click)="onProfileToggle(profileEdit)"
                    >
                      Edit
                    </button>
                    <button
                      mat-menu-item
                      class="text-white"
                      (click)="signOut()"
                    >
                      Sign-out
                    </button>
                  </mat-menu>
                </span>
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-3 align-items-center">
          <div class="d-flex justify-content-center align-items-center">
            <div class="profile-pic">
              <img
                [src]="
                  userDetails?.pictureFileLocations ||
                  'assets/images/img/profile-avatar-1.png'
                "
                (click)="viewProfilePic(userDetails.pictureFileLocations)"
                class="cursor-hand img"
                alt="IMAGE"
              />
              <div
                class="spinner-border text-primary"
                role="status"
                *ngIf="spinner"
              ></div>
              <input
                type="file"
                id="BtnBrowseHidden"
                name="files"
                class="d-none"
                (change)="updateProfilePic($event)"
              />
              <div class="col text-end cursor-hand" *ngIf="canEdit">
                <span class="edit-image" mat-button [matMenuTriggerFor]="menu" *ngIf="userDetails.pictureFileLocations">
                  <i class="fas fa-ellipsis-v text-white"></i>
                  <mat-menu
                    #menu="matMenu"
                    class="input-field form-control bg-app-primary"
                  >
                    <button mat-menu-item>
                      <label
                        for="BtnBrowseHidden"
                        id="LblBrowse"
                        class="text-white cursor-hand"
                        >Change Image
                      </label>
                    </button>
                    <button
                      mat-menu-item
                      class="text-white"
                      (click)="removeImage()"
                    >
                      Remove Image
                    </button>
                  </mat-menu>
                </span>
                <span class="edit-image" mat-button [matMenuTriggerFor]="menu" *ngIf="!userDetails.pictureFileLocations">
                  <i class="fas fa-ellipsis-v text-white"></i>
                  <mat-menu
                    #menu="matMenu"
                    class="input-field form-control bg-app-primary"
                  >
                    <button mat-menu-item>
                      <label
                        for="BtnBrowseHidden"
                        id="Lbl1Browse"
                        class="text-white cursor-hand"
                        >Add image
                      </label>
                    </button>
                  </mat-menu>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div *ngIf="!profileEdit">
            <div class="row pe-3 ps-3">
              <div class="mb-3">
                <div class="text-white title text-capitalize">
                  {{ userDetails.name }}
                </div>
              </div>
              <div
                class="row text-white"
                
              >
                <div class="mb-2" *ngIf="userDetails?.industryRole?.length !== 0">
                  <img
                    src="../../../../assets/images/svg/theaters.svg"
                    class="me-1 mb-1"
                  />
                  {{ userDetails.industryRole }}
                </div>
                <div
                  class="text-capitalize mb-2"
                  *ngIf="
                    userDetails?.location?.city || userDetails.location?.country
                  "
                >
                  <img
                    src="../../../../assets/images/svg/location_on.svg"
                    class="me-2 mb-1"
                  />{{
                    dataFormator.locationArray(
                      userDetails?.location?.city,
                      userDetails.location?.country
                    )
                  }}
                </div>
                <div class="text-white" *ngIf="visibility('email')">
                  <div
                    class="mb-3 w-100 d-flex align-items-center justify-content-between"
                    *ngIf="userDetails.email"
                  >
                    <div class="text-white">
                      <img
                        src="../../../../assets/images/svg/mail2.svg"
                        class="me-1 mb-1"
                      />
                      {{ userDetails.email }}
                    </div>
                    <div
                      class="text-end anim-i"
                      *ngIf="canEdit"
                      (click)="toggle('email')"
                    >
                      <i *ngIf="hide" class="fa fa-solid fa-eye me-2"></i>
                      <i
                        *ngIf="!hide"
                        class="fa fa-solid fa-eye-slash pe-2"
                      ></i>
                    </div>
                  </div>
                </div>
                <div class="text-capitalize mb-1">
                  {{ userDetails.languages }}
                </div>
              </div>
              <div class="text-scroll mt-1 mx-auto text-cfont-secondary2">
                {{ userDetails.bio }}
              </div>
            </div>
          </div>
          <div class="col-sm-12 form-details">
            <div class="" *ngIf="profileEdit">
              <form [formGroup]="updateProfile">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6 col-sm-12 form-details mb-3 mt-3">
                      <div class="mb-3">
                        <div class="text-white">FirstName</div>
                        <input
                          type="text"
                          class="input-field form-control"
                          formControlName="firstName"
                        />
                      </div>
                      <div class="mb-3">
                        <div class="text-white">LastName</div>
                        <input
                          type="text"
                          class="input-field form-control"
                          formControlName="lastName"
                        />
                      </div>
                      <div class="text-white">Role:</div>
                      <div class="mb-3">
                        <select
                          class="input-field form-control"
                          id="rolesSelect"
                          formControlName="industryRole"
                          (change)="onItemSelect($event, 'industryRole')"
                        >
                          <option
                            *ngFor="let role of roleLists"
                            [value]="role"
                            [disabled]="isItemSelected(role, 'industryRole')"
                             (keydown)="handleKeydown($event, 'industryRole')"
                             
                          >
                            {{ role }}
                          </option>
                        </select>
                      </div>

                      <!-- Displaying selected roles as badges -->
                      <div class="selected-roles mt-2">
                        <span
                          *ngFor="
                            let role of config['industryRole']['selectArray']
                          "
                          class="badge bg-primary me-2"
                        >
                          {{ role }}
                          <button
                            type="button"
                            class="btn-close btn-close-white ms-2"
                            aria-label="Close"
                            (click)="removeItem(role, 'industryRole')"
                          ></button>
                        </span>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 form-details">
                      <div formGroupName="location">
                        <div class="mb-3 mt-3">
                          <div class="text-white">City</div>
                          <input
                            type="text"
                            class="input-field form-control"
                            formControlName="city"
                          />
                        </div>
                        <div class="mb-3">
                          <div class="text-white">Country</div>
                          <input
                            type="text"
                            class="input-field form-control"
                            formControlName="country"
                          />
                        </div>
                      </div>
                      <!-- Language Selection Dropdown -->
                      <div class="text-white">Languages:</div>
                      <div class="mb-3">
                        <select
                          class="input-field form-control"
                          id="languagesSelect"
                          formControlName="languages"
                          (change)="onItemSelect($event, 'languages')"
                        >
                          <option
                            *ngFor="let language of languages"
                            [value]="language"
                            [disabled]="isItemSelected(language, 'languages')"
                          >
                            {{ language }}
                          </option>
                        </select>
                      </div>

                      <!-- Displaying selected languages as badges -->
                      <div class="selected-languages mt-2">
                        <span
                          *ngFor="
                            let language of config['languages']['selectArray']
                          "
                          class="badge bg-success me-2"
                        >
                          {{ language }}
                          <button
                            type="button"
                            class="btn-close btn-close-white ms-2"
                            aria-label="Close"
                            (click)="removeItem(language, 'languages')"
                          ></button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="text-white">Email</div>
                  <input
                    type="text"
                    class="input-field form-control"
                    formControlName="email"
                  />
                </div>

                <div class="mb-2">
                  <div class="text-white">Bio</div>
                  <textarea
                    name=""
                    class="input-field form-control"
                    formControlName="bio"
                  ></textarea>
                </div>

                <div class="d-flex pt-2 text-center justify-content-end">
                  <div>
                    <button
                    type="button"
                      id="abutton"
                      class="btn btn-grad-ff text-white m-1"
                      (click)="onSave()"
                    >
                      Save
                    </button>
                  </div>
                  <div>
                    <button type ="button"
                      class="btn btn-secondary m-1"
                      (click)="onProfileToggle(profileEdit)"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- project -->

      <div class="row mt-3">
        <div class="d-flex justify-content-between">
          <h4 class="text-white anim-i mt-2">PROJECTS</h4>
          <button
            class="btn btn-grad-ff text-white"
            (click)="onToggle('projectAdd')"
            *ngIf="canEdit"
          >
            <i class="fa fa-solid fa-plus me-3"></i> New Project
          </button>
        </div>
      </div>
      <div class="bg-app-secondary2 mb-4">
        <div
          class="details-edit w-100 p-4 pb-3"
          *ngIf="recordAddInfo.projectAdd"
        >
          <form [formGroup]="addForm">
            <div class="row">
              <div class="col-md-12 sm-12">
                <div
                  class="d-flex justify-content-between align-items-center flex-wrap"
                >
                  <div class="mb-3">
                    <h4 class="mb-0 text-white">Project Name:</h4>
                    <input
                      type="text"
                      class="input-field form-control"
                      formControlName="string1"
                    />
                  </div>
                  <div class="mb-3">
                    <h4 class="mb-0 text-white">From :</h4>
                    <input
                      type="month"
                      class="input-field form-control"
                      formControlName="date1"
                      placeholder="foo"
                    />
                  </div>

                  <div class="mb-3">
                    <h4 class="mb-0 text-white">to :</h4>
                    <input
                      type="month"
                      class="input-field form-control"
                      formControlName="date2"
                      placeholder="foo"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="mb-3">
                  <h5 class="mb-0 text-white">Role:</h5>
                  <select
                    class="input-field form-control"
                    aria-label="Default select example"
                    formControlName="string2"
                  >
                    <option value="" disabled selected>
                      Select your option
                    </option>
                    <option *ngFor="let role of roleLists" [value]="role">
                      {{ role }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="mb-3">
                  <h5 class="mb-0 text-white">Link To Project (Optional)</h5>
                  <input
                    type="text"
                    class="input-field form-control"
                    formControlName="string4"
                  />
                </div>
              </div>
            </div>

      
            <div>
              <h4 class="mb-0 text-white">Details:</h4>
              <textarea
                name=""
             
                class="input-field form-control"
                id=""
                formControlName="text1"
              ></textarea>

              <!-- <a href="" class="text-white">Link to project <span><i class="ms-2 fa fa-solid fa-arrow-right"></i></span></a> -->
            </div>
            <div
              class="text-end mt-3 text-white anim-i"
              (click)="onAddSubmit('project', 'projectAdd')"
            >
              <span>Save </span>
            </div>
            <div
              class="text-end mt-3 text-white anim-i"
              (click)="recordAddInfo.projectAdd = false"
            >
              <span>Cancel </span>
            </div>
          </form>
        </div>
      </div>

      <!-- projectView -->
      <div class="row mb-2">
        <div
          class="col-lg-3 col-md-4 col-sm-12 mb-2"
          *ngFor="let course of recordInfo['project']; let i = index"
        >
          <div class="card bg-app-secondary2 point rounded p-3">
            <div
              class="d-flex justify-content-between w-100"
              *ngIf="!course.isEditMode"
            >
              <div class="row">
                <div class="col-lg-10">
                  <h2 class="text-white">{{ course.string1 }}</h2>
                  <span class="text-cfont-secondary2">
                    {{ dataFormator.dateFormat(course.date1, course.date2) }}
                  </span>
                </div>
                <div class="col text-end cursor-hand" *ngIf="canEdit">
                  <span
                    class="edit-image"
                    mat-button
                    [matMenuTriggerFor]="menu"
                  >
                    <i class="fas fa-ellipsis-v text-white"></i>
                    <mat-menu
                      #menu="matMenu"
                      class="input-field form-control bg-app-primary"
                    >
                      <button
                        mat-menu-item
                        (click)="onEditClick(course)"
                        class="text-white"
                      >
                        Edit
                      </button>
                      <button
                        mat-menu-item
                        (click)="onDeleteClick(course.id, 'project')"
                        class="text-white"
                      >
                        Delete
                      </button>
                    </mat-menu>
                  </span>
                </div>

                <div class="mt-3">
                  <h5 class="text-cfont-secondary2">Role</h5>
                  <h4 class="text-white">
                    {{ course.string2 }}
                  </h4>
                </div>
                <div class="mt-3">
                  <h5 class="text-cfont-secondary2">Details</h5>
                  <h4 class="text-white text-height">{{ course.text1 }}</h4>
                </div>
                <div *ngIf="course.string4" class="text-end mt-3">
                  <a
                    href=""
                    class="text-white text-decoration-underline"
                    [href]="course.string4"
                    target="_blank"
                    rel="noreferrer noopener"
                    >View Projects</a
                  >
                </div>
              </div>
            </div>
            <div class="row" *ngIf="course.isEditMode">
              <form [formGroup]="addForm">
                <div class="mb-3">
                  <h5 class="mb-0 text-white">Project Name:</h5>
                  <input
                    type="text"
                    class="input-field form-control"
                    formControlName="string1"
                  />
                </div>
                <div class="mb-3 col">
                  <h5 class="mb-0 text-white">From :</h5>
                  <input
                    type="month"
                    class="input-field form-control"
                    formControlName="date1"
                  />
                  <h5 class="mb-0 text-white">to :</h5>
                  <input
                    type="month"
                    class="input-field form-control"
                    formControlName="date2"
                  />
                </div>
                <div class="mb-3">
                  <h5 class="mb-0 text-white">Role:</h5>
                  <select
                    class="input-field form-control"
                    aria-label="Default select example"
                    formControlName="string2"
                  >
                    <option value="" disabled selected>
                      Select your option
                    </option>
                    <option *ngFor="let role of roleLists" [value]="role">
                      {{ role }}
                    </option>
                  </select>
                </div>
                <div class="col-md-6 col-sm-12">
                  <div class="mb-3">
                    <h5 class="mb-0 text-white">Link to Project(optional)</h5>
                    <input
                      type="text"
                      class="input-field form-control"
                      formControlName="string4"
                    />
                  </div>
                </div>
                <div>
                  <h5 class="mb-0 text-white">Details:</h5>
                  <textarea
                    name=""
                    class="input-field form-control"
                    id=""
                    formControlName="text1"
                  ></textarea>
                </div>
                <div class="d-flex justify-content-end mt-4 text-white anim-i">
                  <div (click)="onEditSubmit(course.id, 'project')">
                    <span class="text-white">Save </span>
                  </div>
                  <div (click)="onCancelClick()">
                    <span class="ms-2"> Cancel </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!-- ACHIEVEMENTS -->
      <div class="row mt-3">
        <div class="d-flex justify-content-between">
          <h4 class="text-white anim-i mt-2">ACHIEVEMENTS & RECOGNITIONS</h4>
          <button
            class="btn btn-grad-ff text-white"
            (click)="onToggle('achievementAdd')"
            *ngIf="canEdit"
          >
            <i class="fa fa-solid fa-plus me-4"></i>New Award
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-12">
          <div class="d-flex justify-content-between w-100">
            <div
              class="card bg-app-secondary2 p-3"
              *ngIf="recordAddInfo.achievementAdd"
            >
              <!-- <div class="d-flex w-100"> -->
              <i class="text-white me-2 pt-2 fa fa-solid fa-certificate"></i>
              <form [formGroup]="addForm">
                <div class="row">
                  <!-- <div class="d-flex justify-content-between"> -->
                  <div>
                    <p class="text-white mb-0 ms-2">Award Name</p>
                    <h4 class="text-white mb-2 ms-2">
                      <input
                        type="text"
                        class="input-field form-control border"
                        formControlName="string1"
                      />
                    </h4>
                  </div>
                  <div>
                    <p class="text-white mb-0 ms-2">Awarded for</p>
                    <p class="text-white mb-2 ms-2">
                      <input
                        type="text"
                        class="input-field form-control border"
                        formControlName="string2"
                      />
                    </p>
                  </div>
                  <div>
                    <p class="text-white mb-0 ms-2">Date</p>
                    <h5 class="text-white ms-2">
                      <input
                        type="month"
                        class="input-field form-control border"
                        formControlName="date1"
                      />
                    </h5>
                  </div>
                  <div class="d-flex justify-content-end anim-i">
                    <div
                      class="mt-2"
                      (click)="onAddSubmit('achievement', 'achievementAdd')"
                    >
                      <span class="text-white">Save </span>
                    </div>
                    <div class="mt-2" (click)="onToggle('achievementAdd')">
                      <span class="ms-2 text-white">Cancel </span>
                    </div>
                  </div>
                </div>
              </form>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>

      <!-- Achive view -->
      <div class="row mt-3">
        <div
          class="col-lg-3 col-md-4 col-sm-12 mb-2"
          *ngFor="let items of recordInfo['achievement']"
        >
          <div
            class="card bg-app-secondary2 point rounded p-3"
            *ngIf="!items.isEditMode"
          >
            <div class="row mb-3">
              <div class="col-lg-10"></div>
              <div class="col text-end cursor-hand" *ngIf="canEdit">
                <span class="edit-image" mat-button [matMenuTriggerFor]="menu">
                  <i class="fas fa-ellipsis-v text-white"></i>
                  <mat-menu
                    #menu="matMenu"
                    class="input-field form-control bg-app-primary"
                  >
                    <button
                      mat-menu-item
                      (click)="onEditClick(items)"
                      class="text-white"
                    >
                      Edit
                    </button>
                    <button
                      mat-menu-item
                      (click)="onDeleteClick(items.id, 'achievement')"
                      class="text-white"
                    >
                      Delete
                    </button>
                  </mat-menu>
                </span>
              </div>
              <div>
                <h5 class="text-cfont-secondary2">Award Name</h5>
                <h3 class="text-white">{{ items.string1 }}</h3>
                <h5 class="text-cfont-secondary2 mt-2">Awarded for</h5>
                <h3 class="text-white mb-0">
                  {{ items.string2 }}
                </h3>
                <h5 class="text-cfont-secondary2 mt-2">Date</h5>
                <h3 class="text-white">
                  {{ dataFormator.dateFormat(items.date1, null) }}
                </h3>
              </div>
            </div>
          </div>
          <div class="card bg-app-secondary2" *ngIf="items.isEditMode">
            <form [formGroup]="addForm">
              <div>
                <div class="p-3">
                  <p class="text-white mb-0">Award Name</p>
                  <h4 class="text-white mb-2">
                    <input
                      type="text"
                      class="input-field form-control border"
                      formControlName="string1"
                    />
                  </h4>
                  <p class="text-white mb-0">Awarded for</p>
                  <p class="text-white mb-0">
                    <input
                      type="text"
                      class="input-field form-control border"
                      formControlName="string2"
                    />
                  </p>
                  <p class="text-white mb-0">Date</p>
                  <h5 class="text-white">
                    <input
                      type="month"
                      class="input-field form-control border"
                      formControlName="date1"
                    />
                  </h5>
                  <div class="d-flex justify-content-end mt-2 anim-i">
                    <div (click)="onEditSubmit(items.id, 'achievement')">
                      <span class="text-white">Save </span>
                    </div>
                    <div class="me-2" (click)="onCancelClick()">
                      <span class="ps-2 text-white"> Cancel </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Networks -->
      <div class="row mt-3 mb-3">
        <div class="d-flex justify-content-between">
          <h4 class="text-white anim-i mt-2">NETWORKS AND ASSOCIATIONS</h4>
          <button
            class="btn btn-grad-ff text-white"
            (click)="onToggle('networkAdd')"
            *ngIf="canEdit"
          >
            <i class="fa fa-solid fa-plus me-2"></i>New Network
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-12">
          <div class="d-flex justify-content-between w-100">
            <div
              class="card-wrap bg-app-secondary2 p-3"
              *ngIf="recordAddInfo.networkAdd"
            >
              <h4 class="m-0 p-0 text-white">Networks</h4>
              <div>
                <form [formGroup]="addForm">
                  <div class="m-0">
                    <h4 class="text-white mb-2">
                      <input
                        type="text"
                        class="input-field form-control border"
                        formControlName="string1"
                      />
                    </h4>
                  </div>
                  <div class="d-flex justify-content-end mt-2 anim-i">
                    <div (click)="onAddSubmit('network', 'networkAdd')">
                      <span class="mb-2 text-white">Save </span>
                    </div>
                    <div (click)="onToggle('networkAdd')">
                      <span class="ms-2 text-white">Cancel </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div
          class="col-lg-3 col-md-4 col-sm-12"
          *ngFor="let items of recordInfo['network']"
        >
          <div
            class="card-wrap bg-app-secondary2 point rounded"
            *ngIf="!items.isEditMode"
          >
            <div class="row p-3">
              <div class="col-lg-10"></div>
              <div class="col text-end cursor-hand" *ngIf="canEdit">
                <span class="edit-image" mat-button [matMenuTriggerFor]="menu">
                  <i class="fas fa-ellipsis-v text-white"></i>
                  <mat-menu
                    #menu="matMenu"
                    class="input-field form-control bg-app-primary"
                  >
                    <button
                      mat-menu-item
                      (click)="onEditClick(items)"
                      class="text-white"
                    >
                      Edit
                    </button>
                    <button
                      mat-menu-item
                      (click)="onDeleteClick(items.id, 'network')"
                      class="text-white"
                    >
                      Delete
                    </button>
                  </mat-menu>
                </span>
              </div>
              <div>
                <h5 class="text-cfont-secondary2">Networks</h5>
                <h4 class="text-white text-height">
                  {{ items.string1 }}
                </h4>
              </div>
            </div>
          </div>
          <div class="card-wrap bg-app-secondary2" *ngIf="items.isEditMode">
            <form [formGroup]="addForm">
              <div class="p-2">
                <h4 class="m-0 p-0 text-white">Networks</h4>
                <h4 class="text-white mb-2">
                  <input
                    type="text"
                    class="input-field form-control"
                    formControlName="string1"
                  />
                </h4>
                <div class="d-flex justify-content-end anim-i">
                  <div (click)="onEditSubmit(items.id, 'network')">
                    <span class="text-white">Save </span>
                  </div>
                  <div class="me-2" (click)="onCancelClick()">
                    <span class="ms-2 text-white">Cancel </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- recommendation2 -->
      <div class="row">
        <div class="d-flex justify-content-between">
          <h4 class="text-white anim-i mt-2">RECOMMENDATIONS</h4>
          <button
            class="btn btn-grad-ff text-white"
            (click)="onToggle('recommendationAdd')"
            *ngIf="canEdit === false"
          >
            <i class="fa fa-solid fa-plus me-2"></i>Add Recommendation
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-12">
          <div class="d-flex justify-content-between">
            <div
              class="card-wrap bg-app-secondary2 p-3 mb-3 pb-2"
              *ngIf="recordAddInfo.recommendationAdd"
            >
              <form [formGroup]="addForm">
                <div class="row">
                  <div class="mb-3">
                    <div class="text-white">feedback</div>
                    <textarea
                      class="input-field form-control"
                      formControlName="text1"
                    ></textarea>
                  </div>
                  <div class="d-flex justify-content-end anim-i">
                    <div (click)="recommendationAdd()">
                      <span class="text-white">Save </span>
                    </div>
                    <div (click)="onToggle('recommendationAdd')">
                      <span class="ms-2 text-white">Cancel </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div
          class="col-lg-3 col-md-4 col-sm-12"
          *ngFor="let items of recordInfo['recommendation']"
        >
          <div
            class="card-wrap bg-app-secondary2 rounded d-flex"
            *ngIf="!items.isEditMode"
          >
            <div class="profile-team">
              <img
                src="{{ items.json1?.pictureFileLocations }}"
                alt="user"
                onerror="this.src ='assets/images/img/profile-avatar-1.png'"
              />
            </div>
            <div class="col">
              <div class="col-md-4 mb-3 me-2">
                <div
                  class="flex-wrap text-end cursor-hand"
                  *ngIf="!canEdit && profileId === items.json1.id"
                >
                  <span
                    class="edit-image"
                    mat-button
                    [matMenuTriggerFor]="menu"
                  >
                    <i class="fas fa-ellipsis-v text-white"></i>
                    <mat-menu
                      #menu="matMenu"
                      class="input-field form-control bg-app-primary"
                    >
                      <button
                        mat-menu-item
                        (click)="onEditClick(items)"
                        class="text-white"
                      >
                        Edit
                      </button>
                      <button
                        mat-menu-item
                        (click)="onDeleteClick(items.id, 'recommendation')"
                        class="text-white"
                      >
                        Delete
                      </button>
                    </mat-menu>
                  </span>
                </div>
                <div *ngIf="!canEdit && profileId === items.json1.id">
                  <h4 class="m-0 text-white">{{ items.json1?.name }}</h4>
                  <h5 class="m-0 p-2 text-white text-height">
                    {{ items.text1 }}
                  </h5>
                </div>
              </div>
              <div *ngIf="canEdit && profileId === items.json1.id">
                <h4 class="m-0 text-white">{{ items.json1?.name }}</h4>
                <h5 class="m-0 p-2 text-white text-height">
                  {{ items.text1 }}
                </h5>
              </div>
              <div *ngIf="profileId !== items.json1.id">
                <h4 class="m-0 text-white">{{ items.json1?.name }}</h4>
                <h5 class="m-0 p-2 text-white text-height">
                  {{ items.text1 }}
                </h5>
              </div>
            </div>
          </div>
          <div class="card-wrap bg-app-secondary2" *ngIf="items.isEditMode">
            <form [formGroup]="addForm">
              <div class="row p-4 pt-3 pb-3">
                <div class="text-white mb-2">RECOMMENDATIONS</div>
                <textarea
                  class="input-field form-control"
                  formControlName="text1"
                ></textarea>
                <div class="d-flex justify-content-end anim-i">
                  <div (click)="onEditSubmit(items.id, 'recommendation')">
                    <span class="text-white">Save </span>
                  </div>
                  <div (click)="onCancelClick()">
                    <span class="ms-2 text-white"> Cancel </span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
