<div class="container pad-t">
  <div class="row mb-3">
    <div class="col-md-12">
      <span class="text-cfont-secondary2 cursor-hand float-end"
        >Mark all as Read</span
      >
    </div>
  </div>
  <div *ngFor="let item of notification">
    <div class="row mb-2">
      <!-- <div class="col-md-12">
                <span class="title6-dim float-start"></span>
                <div class="title6-dim float-md-end">11/11/24, 1:15 PM</div>
            </div> -->
    </div>
    <div
      class="card-wrap card-wrap--hover rounded p-4 mb-4"
      (click)="goToProject(item.notificationDetails.projectId)"
    >
      <div class="d-flex align-items-center">
        <div class="position-relative nofication-ff user-thumb">
          <img src="../../../../assets/images/user-img.png" alt="" />
          <span
            class="position-absolute top-0 start-100 translate-middle p-1 bg-success rounded-circle"
          >
            <span class="visually-hidden">New alerts</span>
          </span>
        </div>
        <div class="float-start detail-dv flex-grow-1 ms-3">
          <h6 class="title6-highlighted">Invitation</h6>
          <h6 class="title6-dim mb-2">
            {{ item.message }}
          </h6>
          <p class="title-normal">{{ item.createdAt | date : "short" }}</p>
        </div>
        <div class="w-50p">
          <!-- <div class="action-btn ms-3">
                        <a href="#"><i class="fa fa-edit"></i></a>
                    </div> -->
        </div>
      </div>

      <div class="mt-3 w-100 d-inline-block">
        <div
          class="float-end"
          *ngIf="
            item.notificationAction === 'action' &&
            item.notificationType === 'invitation'
          "
        >
          <button
            class="btn btn-secondary-outline me-2"
            (click)="
              invitationCancel(item.entrollmentId); $event.stopPropagation()
            "
          >
            Cancel
          </button>
          <button
            class="btn btn-grad-ff"
            (click)="
              invitationAccept(item.entrollmentId); $event.stopPropagation()
            "
          >
            Accept
          </button>
        </div>
      </div>
      <div
        class="ps-2 float-end"
        *ngIf="
          item.notificationAction === 'action' &&
          item.notificationType === 'investment'
        "
      >
        <button class="btn btn-grad-ff">Invest</button>
      </div>
    </div>
    <!-- <div class="">
            <div class="card-wrap p-3 mb-3 min-h-auto">
                <div class="d-flex align-items-center">
                    <div class="position-relative nofication-ff">
                        <img src="../../../../assets/images/img/tour.png" alt="">
                        <span class="position-absolute top-0 translate-middle p-1 bg-success rounded-circle">
                            <span class="visually-hidden">New alerts</span>
                        </span>
                    </div>
                    <div class="float-start detail-dv flex-grow-1 ms-3">
                        <h6 class="title6-highlighted">Profile visit</h6>
                        <h6 class="title6-dim mb-2">
                            Shyam Pushkaran viewed your profile recently
                        </h6>
                        <p class="title-normal">
                            1 hour ago
                        </p>
                    </div>
                    <div class="w-50p">
                        <div class="action-btn ms-3">
                            <a href="#"><i class="fa fa-edit"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
  </div>
</div>
