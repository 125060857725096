import { Component } from '@angular/core';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(
   
  ) {}



}
