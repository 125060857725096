import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { TaskService } from 'src/app/core/service/task.service';
import { NotificationComponent } from 'src/app/modules/notifcation/notification/notification.component';
import { NotificationViewComponent } from 'src/app/modules/notifcation/notification-view/notification-view.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
  constructor(
    private router: Router,
    private apiHelper: ApiHelper,
    private toastrService: ToastrService,
    private taskService: TaskService,
    private dialog: MatDialog
  ) {}

  profile!: any;
  userSession!: any;

  ngOnInit(): void {
    let sessionInLocalStorage: any = localStorage.getItem('session');
    let session = JSON.parse(sessionInLocalStorage);
    this.getProfileRecord(session.profileId);
  }

  getProfileRecord(id: string) {
    this.apiHelper.post({ id: id }, ApiEndPoints.profileGet).subscribe(
      (response) => {
        if (response.data === null) {
          this.signOut();
          return;
        }
        // this.userSession = response.data;
        this.taskService.updateUserDetails(response.data);
        this.taskService.currentUserDetails.subscribe(
          // update the component's property
          (details) => (this.userSession = details)
        );
      },
      (error) => {
        this.toastrService.error(error.error.message);
      }
    );
  }

  signOut() {
    localStorage.removeItem('session');
    localStorage.removeItem('profile');
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
  }

  viewProfile(id: string) {
    this.router.navigate(['view/profile/view', id]);
  }

  notificationPop() {
    // this.dialog.open(NotificationViewComponent)
    this.router.navigate(['view/notifications']);
  }
}
