import { Component, OnInit } from '@angular/core';
import { ApiEndPoints } from 'src/app/core/constants';
import { languages, roleLists } from 'src/app/core/datas';
import { ApiHelper } from 'src/app/core/service/api.helper';
import { ConfigCheckService } from 'src/app/core/service/configCheck.service';
import { dataformatorService } from 'src/app/core/service/dataformator.service';
import { SortingService } from 'src/app/core/service/sorting.service';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.scss'],
})
export class ProfileListComponent implements OnInit {
  countriesAndCities: string[] = [];

  roleLists = this.sortService.sortArrayAlphabetically(roleLists);

  locations: any;

  combinedSet = new Set<string>();

  profiles: any;

  profileData: boolean = true;

  newState!: boolean;

  userProfile: any;

  constructor(
    private apiHelper: ApiHelper,
    private sortService: SortingService,
    public dataFormator: dataformatorService,
    public dataCheckConfig: ConfigCheckService
  ) {}

  ngOnInit(): void {
    let userSessionString: any = localStorage.getItem('session');
    let session = JSON.parse(userSessionString);
    this.userProfile = session.profileId;
  }

  getProjects(offset = 1, limit = 50) {
    this.apiHelper
      .post(
        {
          pagenation: {
            limit: limit,
            offset: offset,
          },
        },
        ApiEndPoints.customSearch,
        true
      )
      .subscribe((response) => {
        this.profiles = response.data;
        if (response.data?.length === limit) {
          this.profileData = true;
        } else {
          this.profileData = false;
        }
      });
  }

  count = 1;
  pagenation(type: string) {
    if (type === 'next') this.count++;
    if (type === 'previous' && this.count > 1) {
      this.count--;
    }
    this.getProjects(this.count);
  }

  searchQuery: any = {
    freeText: '',
    role: '',
    location: '',
  };

  getSearchResults(criteria: any) {
    this.apiHelper
      .post({ filters: criteria }, ApiEndPoints.customSearch)
      .subscribe((response) => {
        this.profiles = response.data;
        if (response.data?.length === 50) {
          this.profileData = true;
        } else {
          this.profileData = false;
        }
      });
  }

  searchFilter(type: string, event: any) {
    this.newState = false;
    let data = event.target.value;
    this.searchQuery[type] = data;
    this.getSearchResults(this.searchQuery);
  }

  onCancel(locationSelect: any, rolesSelect: any) {
    this.newState = false;
    locationSelect.value = '';
    rolesSelect.value = '';

    this.getSearchResults({
      freeText: '',
      role: '',
      location: '',
    });
  }

  profileSearchResultData(newItem: any) {
    this.profiles = newItem;
    this.profiles.forEach((item: any) => {
      if (item && item.location) {
        if (item.location.country && item.location.country.trim() !== '') {
          this.combinedSet.add(item.location.country.toLowerCase());
        }

        if (item.location.city && item.location.city.trim() !== '') {
          this.combinedSet.add(item.location.city.toLowerCase());
        }
      }
    });
    this.countriesAndCities = Array.from(this.combinedSet);
    this.locations = this.sortService.sortArrayAlphabetically(
      this.countriesAndCities
    );
  }

  // isValid(item:any){
  //   const val = this.dataCheckConfig.checkConfigV2(item,this.profileViewConfig)
  //   console.log(val)
  // }
}
